import api from "@/tools/Api";
import urls from "@/tools/Urls";
import message from "@/tools/Message";
import store from "@/store";
import { _t } from "@/tools/Utils";
import { event_colors } from "@/packages/admin/schema/calender/COLORS";

export default {
  async getClasses({
    page = 1,
    limit = 10,
    search = "",
    level = "",
    all = false,
    baseType = "",
    dispatch = null,
    teacherPublicKey = [],
    role = null,
    classroomId = [],
    filterByStudent = null,
    filterByAcademicConsultant = null,
  }) {
    try {
      let schema = {
        page: {
          index: page,
          size: limit,
        },
        where: [],
        order: [
          {
            column: "createMoment",
            descending: true,
          },
        ],
      };
      if (role !== "Admin") {
        schema.where.push([
          {
            column: "state",
            comparison: "NotEqual",
            operand: "Deleted",
          },
        ]);
      }
      if (search)
        schema.where.push([
          {
            column: "classroomName",
            comparison: "Contains",
            operand: search,
          },
          {
            column: "classroomName",
            comparison: "Contains",
            operand: search.charAt(0).toUpperCase() + search.slice(1),
          },
          {
            column: "classroomName",
            comparison: "Contains",
            operand: search.toLowerCase(),
          },
        ]);
      if (level) {
        schema.where.push([
          {
            column: "classroomLevel",
            comparison: "Equal",
            operand: level,
          },
        ]);
      }
      if (classroomId.length) {
        schema.where.push(
          classroomId.map((id) => ({
            column: "classroomId",
            comparison: "Equal",
            operand: id,
          }))
        );
      }
      if (baseType) {
        schema.where.push([
          {
            column: "baseType",
            comparison: "Equal",
            operand: baseType,
          },
        ]);
      }
      if (teacherPublicKey.length) {
        schema.where.push(
          teacherPublicKey.map((key) => ({
            column: "teacherPublicKey",
            comparison: "Equal",
            operand: key,
          }))
        );
      }
      if (all) {
        schema.page.index = 1;
        schema.page.size = -1;
      }
      const url =
        role === "Teacher"
          ? urls.api("admin_classes_teacher")
          : urls.qs("admin_classes", {
              filterByStudent,
              filterByAcademicConsultant,
            });

      const { data } = await api.post(url, schema);
      let classes = data.data.map((anyClass) => {
        return {
          ...anyClass,
          times: anyClass.times.map((time) => {
            const status = anyClass.students.find((s) => {
              return s.presenceStatus.find(
                (s) =>
                  s.availableTimeId === time.availableTimeId &&
                  s.start === time.start &&
                  s.end === time.end
              );
            });
            const info = anyClass.teachingDayInformation?.find(
              (i) =>
                i.availableTimeId === time.availableTimeId &&
                i.start === time.start &&
                i.end === time.end
            );

            return {
              ...time,
              singleClass: anyClass,
              color: !time.financialConfirmation
                ? event_colors.reserved
                : time.state === "Cancel"
                ? event_colors.cancel
                : !info || !status
                ? event_colors.canceled
                : time.changesConfirmedByTeacher &&
                  !time.changesConfirmedBySchedulingAdmin
                ? event_colors.new
                : time.changesConfirmedByTeacher &&
                  time.changesConfirmedBySchedulingAdmin
                ? event_colors.waiting_for_class
                : event_colors.passed,
              timed: true,
            };
          }),
        };
      });

      if (dispatch) {
        await store.dispatch(`${dispatch}`, data.data);
      }
      return {
        classes: data.data,
        editedTimeClasses: classes,
        pageCount: Math.ceil(data.totalCount / limit),
      };
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getSingleClass({ id }) {
    try {
      let schema = {
        where: [
          [
            {
              column: "classroomId",
              comparison: "Equal",
              operand: id,
            },
          ],
        ],
        order: [
          {
            column: "createMoment",
            descending: true,
          },
        ],
      };
      const { data } = await api.post(urls.api("admin_classes"), schema);
      if (!data.data.length) return false;

      let classes = data.data.map((anyClass) => {
        return {
          ...anyClass,
          times: anyClass.times.map((time) => {
            const status = anyClass.students.find((s) => {
              return s.presenceStatus.find(
                (s) =>
                  s.availableTimeId === time.availableTimeId &&
                  s.start === time.start &&
                  s.end === time.end
              );
            });
            const info = anyClass.teachingDayInformation?.find(
              (i) =>
                i.availableTimeId === time.availableTimeId &&
                i.start === time.start &&
                i.end === time.end
            );

            return {
              ...time,
              singleClass: anyClass,
              color: !time.financialConfirmation
                ? event_colors.reserved
                : time.state === "Cancel"
                ? event_colors.cancel
                : !info || !status
                ? event_colors.canceled
                : time.changesConfirmedByTeacher &&
                  !time.changesConfirmedBySchedulingAdmin
                ? event_colors.new
                : time.changesConfirmedByTeacher &&
                  time.changesConfirmedBySchedulingAdmin
                ? event_colors.waiting_for_class
                : event_colors.passed,
              timed: true,
              info,
              status,
            };
          }),
        };
      });

      await store.dispatch(`class/setClass`, classes[0]);
      return true;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getFilteredClasses({
    start,
    end,
    filterByClassroom = [],
    filterByTeacher = [],
    filterByStudent = [],
    filterByAcademicConsultant = null,
    filterByLessonType = null,
    all = null,
  }) {
    try {
      let params = {
        start,
        end,
        classroomsId: filterByClassroom,
        teachersPublicKey: filterByTeacher,
        studentsPublicKey: filterByStudent,
        academicConsultantPublicKey: filterByAcademicConsultant,
        lessonType: filterByLessonType,
      };
      if (all) {
        params.size = -1;
        params.index = 1;
      }
      const { data } = await api.post(urls.qs("getFilteredClasses"), params);
      let events = data.data.map((anyClass) => {
        return anyClass.times.map((time) => {
          const status = anyClass.students.find((s) => {
            return s.presenceStatus.find(
              (s) =>
                s.availableTimeId === time.availableTimeId &&
                s.start === time.start &&
                s.end === time.end
            );
          });
          const info = anyClass.teachingDayInformation?.find(
            (i) =>
              i.availableTimeId === time.availableTimeId &&
              i.start === time.start &&
              i.end === time.end
          );

          return {
            ...time,
            singleClass: anyClass,
            classroomId: anyClass.classroomId,
            baseType: anyClass.lessonType,
            lessonType: anyClass.lessonType,
            classroomName: anyClass.classroomName,
            classroomLevel: anyClass.classroomLevel,
            students: anyClass.students,
            teacherPublicKey: anyClass.teacherPublicKey,
            teacherName: anyClass.teacherName,
            end: time.end * 1000,
            start: time.start * 1000,
            color: !time.financialConfirmation
              ? event_colors.reserved
              : time.state === "Cancel"
              ? event_colors.cancel
              : !info || !status
              ? event_colors.canceled
              : time.changesConfirmedByTeacher &&
                !time.changesConfirmedBySchedulingAdmin
              ? event_colors.new
              : time.changesConfirmedByTeacher &&
                time.changesConfirmedBySchedulingAdmin
              ? event_colors.waiting_for_class
              : event_colors.passed,
            timed: true,
          };
        });
      });
      return { events: events.flat() };
    } catch (e) {
      console.log(e);
    }
  },
  async getClassroomsTimes({
    teacherPublicKey = null,
    baseType = null,
    timeState = null,
    start = null,
    end = null,
  }) {
    try {
      const { data } = await api(
        urls.qs("getClassroomsTimes", {
          teacherPublicKey,
          baseType,
          timeState,
          start,
          end,
        })
      );
      const classTimes = data.map((time) => {
        return {
          ...time,
          // teacherPublicKey: table.teacherPublicKey,
          // teacherName: table.teacherName,
          lesson: [time.baseType],
          end: time.end * 1000,
          start: time.start * 1000,
          color: !time.financialConfirmation
            ? event_colors.reserved
            : time.state === "haveclass"
            ? event_colors.have_class
            : time.state === "waitingforclass"
            ? event_colors.waiting_for_class
            : time.state === "canceled"
            ? event_colors.cancel
            : event_colors.full,
          timed: true,
        };
      });
      // console.log(classTimes, "classroom times");
      await store.dispatch("class/setClassTimes", classTimes);
      return classTimes;
    } catch (e) {
      console.log(e);
    }
  },
  async editClass(payload) {
    try {
      const { isSuccess } = await api.put(urls.api("edit_class"), payload);
      if (isSuccess) message.success("Class confirmed");
      return isSuccess;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async editTimesAndLessons(payload) {
    try {
      const { isSuccess } = await api.put(
        urls.api("edit_class_times_lessons"),
        payload
      );
      if (isSuccess) message.success("Class Editted");
      return isSuccess;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async editTimeDetailOfClass(payload) {
    try {
      const { isSuccess } = await api.put(
        urls.api("edit_time_of_class"),
        payload
      );
      if (isSuccess) message.success("Class confirmed");
      return isSuccess;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getNotAvailableTimesOfStudent({ publicKeys = [] }) {
    try {
      // const { data } = await api.get(
      //   `${urls.api(
      //     "not_available_students_times"
      //   )}?studentsPublicKey=${publicKeys}`
      // );
      const { data } = await api.get(
        urls.qs("not_available_students_times", {
          studentsPublicKey: publicKeys,
        })
      );
      const events = data
        .map((table) => {
          return table.notAvailableTimes.map((time) => {
            return {
              ...time,
              // teacherPublicKey: table.teacherPublicKey,
              // teacherName: table.teacherName,
              // lesson: table.lessonName,
              baseType: time.classroomBaseType,
              end: time.end * 1000,
              showTeacher: false,
              start: time.start * 1000,
              color: "#EF2929",
              timed: true,
            };
          });
        })
        .flat();
      // console.log(data, "getNotAvailableTimesOfStudent");
      // console.log(events);
      await store.dispatch("class/setNotAvailableTimes", events);
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getNotAvailableRoomsAndTimes({ teacherPublicKey }) {
    try {
      // const { data } = await api.get(
      //   `${urls.api(
      //     "not_available_students_times"
      //   )}?studentsPublicKey=${publicKeys}`
      // );
      const { data } = await api.get(
        urls.qs("not_available_rooms_times", { teacherPublicKey })
      );
      const events = data.map((time) => {
        return {
          ...time,
          end: time.end * 1000,
          start: time.start * 1000,
          showTeacher: true,
          color: "#65635D",
          timed: true,
        };
      });

      console.log(events, "getNotAvailableTimes and rooms");
      await store.dispatch("class/setStudentsEvents", events);
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async createClass(payload) {
    try {
      const { data } = await api.post(urls.api("create_class"), payload);
      console.log(data, "class created successfully");
      if (data) {
        message.success(_t("CREATE_SUCCESS"));
        return data;
      }
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getClassNumbers() {
    try {
      const { data } = await api.get(urls.api("get_class_numbers"));
      await store.dispatch("class/setClassroomNumbers", data);
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async editStudentExam(payload) {
    try {
      const res = await api.put(urls.api("edit_student_exam"), payload);
      if (res.data) message.success("Exam edited successfully");
      return res.data;
    } catch (e) {
      message.error(e.response.data.Message);
      throw e.response.data;
    }
  },
  async editStudentHomework(payload) {
    try {
      const res = await api.put(urls.api("edit_student_homework"), payload);
      if (res.data) message.success("Homework edited successfully");
      return res.data;
    } catch (e) {
      message.error(e.response.data.Message);
      throw e.response;
    }
  },
  async editClassTeach(payload) {
    try {
      const res = await api.put(urls.api("edit_class_teaching"), payload);
      if (res.data) message.success("Lesson edited successfully");
      return res.data;
    } catch (e) {
      message.error(e.response.data.Message);
      throw e.response.data;
    }
  },
  async editClassReview(payload) {
    try {
      const res = await api.put(urls.api("edit_class_review"), payload);
      if (res.data) message.success("Lesson edited successfully");
      return res.data;
    } catch (e) {
      message.error(e.response.data.Message);
      throw e.response.data;
    }
  },
  async addLessonToClass(payload) {
    try {
      const res = await api.put(urls.api("add_lesson_to_class"), payload);
      if (res.data) message.success("Lesson added successfully");
      return res.data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async addSpecificationToClass(payload) {
    try {
      const res = await api.put(urls.api("addSpecificationToClass"), payload);
      if (res.data) message.success("Course added successfully");
      return res.data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async editLessonOfClass(payload) {
    try {
      const res = await api.put(urls.api("edit_lesson_of_class"), payload);
      if (res.data) message.success("Lesson edited successfully");
      return res.data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async removeStudentFromClass(payload) {
    try {
      const { data } = await api.put(
        urls.api("remove_student_from_class"),
        payload
      );
      if (data) message.success("Student deleted successfully");
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async presentInformation(payload) {
    try {
      const { data } = await api.put(
        urls.api("add_present_information"),
        payload
      );
      if (data) return message.success("Edited successfully");
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async setTeachingDayInfo(payload) {
    try {
      const { isSuccess } = await api.put(
        urls.api("teaching_day_info"),
        payload
      );
      if (isSuccess) message.success("Added successfully");
      return isSuccess;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getClassroomsOfUser({ publicKey, dispatch }) {
    try {
      const { data } = await api.get(
        urls.qs("classroom_of_user", { publicKey })
      );
      if (dispatch) {
        await store.dispatch(`${dispatch}`, data);
      }
      console.log(data, "classrooms of user");
      let events = data.map((anyClass) => {
        return anyClass.times.map((time) => {
          return {
            ...time,
            singleClass: anyClass,
            classroomId: anyClass.classroomId,
            baseType: anyClass.baseType,
            classroomName: anyClass.classroomName,
            classroomLevel: anyClass.classroomLevel,
            teacherPublicKey: anyClass.teacherPublicKey,
            teacherName: anyClass.teacherName,
            end: time.end * 1000,
            start: time.start * 1000,
            color: !time.financialConfirmation
              ? event_colors.reserved
              : event_colors.waiting_for_class,
            timed: true,
          };
        });
      });
      console.log(events.flat(), "sessions");
      return { events: events.flat(), classrooms: data };
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getPassedLessonsOfClass({ classroomId }) {
    try {
      const { data } = await api.get(
        urls.qs("get_passed_lessons_of_class", { classroomId })
      );
      await store.dispatch("class/setPassedLessons", data);
    } catch (e) {
      console.log(e);
    }
  },
  async deleteClass({ classroomId }) {
    try {
      const { data, isSuccess } = await api.delete(
        urls.api("destroy_classroom"),
        {
          data: { classroomId },
        }
      );
      if (isSuccess) message.success("Deleted successfully");
      console.log(data);
      return isSuccess;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async disableClass(payload) {
    try {
      const { isSuccess } = await api.delete(urls.api("disable_classroom"), {
        data: { ...payload },
      });
      if (isSuccess) message.success("Disabled successfully");
      return isSuccess;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getSessionsOfLesson({ classroomId, lessonId }) {
    try {
      const { data } = await api.get(
        urls.qs("getSessionsOfLesson", { classroomId, lessonId })
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async deleteLessonFromClass(payload) {
    try {
      const { isSuccess } = await api.put(
        urls.api("deleteLessonFromClass"),
        payload
      );
      if (isSuccess) message.success(_t("DELETE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async removeTimesOfClassroom({ classroomId }) {
    try {
      const { isSuccess } = await api.put(urls.api("removeTimesOfClassroom"), {
        classroomId,
      });
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async cancelTimeInClass(payload) {
    try {
      const { isSuccess } = await api.put(
        urls.api("cancel_time_in_classroom"),
        payload
      );
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      console.log(e);
    }
  },
  async uploadHomeworkFile(file, name) {
    try {
      const { data } = await api.post(
        urls.qs("uploadHomeworkFile", { name }),
        file
      );
      if (data) message.success(_t("UPDATE_SUCCESS"));
      return data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async deleteHomeworkFile(name) {
    try {
      const { isSuccess } = await api.delete(
        urls.qs("deleteHomeworkFile") + `/${name}`
      );
      if (isSuccess) message.success(_t("DELETE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async resetSessionInfo(payload) {
    try {
      const { isSuccess } = await api.put(
        urls.api("resetSessionInfo"),
        payload
      );
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async deleteStudentExam(payload) {
    try {
      const { isSuccess } = await api.put(
        urls.api("deleteStudentExam"),
        payload
      );
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      console.log(e);
    }
  },
  async getBalanceOfStudentsOfClassroom(classroomId) {
    try {
      const { data } = await api.get(
        urls.qs("getBalanceOfStudentsOfClassroom", { classroomId })
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async addNewHomeworkToStudents(payload) {
    try {
      const { isSuccess } = await api.put(
        urls.api("addNewHomeworkToStudents"),
        payload
      );
      console.log(isSuccess);
      if (isSuccess) message.success(_t("ADD_SUCCESS"));
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async toggleArchiveClass(payload) {
    try {
      const { isSuccess } = await api.put(urls.api("archiveClass"), payload);
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async generateReportForParents(payload) {
    try {
      const { data, isSuccess } = await api.post(
        urls.api("generateReportForParents"),
        payload
      );
      if (isSuccess) message.success("Report generated successfully");
      return data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async getSessionReport(id) {
    try {
      const { data } = await api.get(`${urls.qs("getSessionReport")}/${id}`);
      return data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
};
