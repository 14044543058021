import message from "@/tools/Message";
import api from "@/tools/Api";
import urls from "@/tools/Urls";
import store from "@/store";
import { _t } from "@/tools/Utils";

export default {
  async getDeposits({
    page = 1,
    limit = 10,
    search = "",
    all = false,
    dispatch = null,
    role = null,
    depositType = null,
    publicKey = null,
    isConfirmed = null,
  }) {
    try {
      let schema = {
        page: {
          index: page,
          size: limit,
        },
        where: [],
        order: [
          {
            column: "createMoment",
            descending: true,
          },
        ],
      };
      if (search)
        schema.where.push([
          {
            column: "classroomName",
            comparison: "Contains",
            operand: search,
          },
          {
            column: "classroomName",
            comparison: "Contains",
            operand: search.charAt(0).toUpperCase() + search.slice(1),
          },
          {
            column: "studentFullName",
            comparison: "Contains",
            operand: search,
          },
          {
            column: "studentFullName",
            comparison: "Contains",
            operand: search.charAt(0).toUpperCase() + search.slice(1),
          },
        ]);

      if (depositType) {
        schema.where.push([
          {
            column: "depositType",
            comparison: "Equal",
            operand: depositType,
          },
        ]);
      }
      if (publicKey) {
        schema.where.push([
          {
            column: "publicKey",
            comparison: "Equal",
            operand: publicKey,
          },
        ]);
      }
      if (all) {
        schema.page.index = 1;
        schema.page.size = -1;
      }
      const { data } = await api.post(
        urls.qs(
          role === "Student"
            ? "finance_get_deposits_student"
            : "finance_get_deposits",
          { isConfirmed }
        ),
        schema
      );
      console.log(data.data, "deposits");
      if (dispatch) {
        await store.dispatch(`${dispatch}`, data.data);
      }
      return {
        deposits: data.data,
        pageCount: Math.ceil(data.totalCount / limit),
      };
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async crateDeposit(payload) {
    try {
      const { data } = await api.post(urls.api("create_deposit"), payload);
      if (data) {
        message.success(_t("CREATE_SUCCESS"));
        return data;
      }
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async editDeposit(payload) {
    try {
      const { data } = await api.put(urls.api("edit_deposit"), payload);
      if (data) {
        message.success(_t("UPDATE_SUCCESS"));
        return data;
      }
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getSumOfDeposits({ depositType }) {
    try {
      const { data } = await api.get(
        urls.qs("sum_of_deposits", { depositType })
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async sumOfStudentDeposits({ publicKey }) {
    try {
      const { data } = await api.get(
        urls.qs("sum_of_student_deposits", { publicKey })
      );
      console.log(data, "sumOfStudentDeposits");
    } catch (e) {
      console.log(e);
    }
  },
  async studentChargeByClassroomId({ classroomId }) {
    try {
      const { data } = await api.get(
        urls.qs("student_charge_by_classroom", { classroomId })
      );
      console.log(data, "studentChargeByClassroomId");
      return data.charges;
    } catch (e) {
      console.log(e);
    }
  },
  async studentCharges() {
    try {
      const { data } = await api.get(urls.qs("student_charges"));
      console.log(data, "studentCharges");
    } catch (e) {
      console.log(e);
    }
  },
  async allStudentsBalance({ dispatch = null, publicKey = null }) {
    try {
      const { data } = await api.get(
        urls.qs("all_student_balance", { studentPublicKey: publicKey })
      );
      console.log(data, "allStudentsBalance");
      if (dispatch) {
        await store.dispatch(`${dispatch}`, data.balances);
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async getGuideForCreateDeposit({
    studentPublicKey,
    classroomId,
    depositType,
  }) {
    try {
      const { data } = await api(
        urls.qs("guideForCreateDeposit", {
          studentPublicKey,
          classroomId,
          depositType,
        })
      );
      console.log(data, "guide");
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async getDepositsHistory({ studentPublicKey = null, classroomId = null }) {
    try {
      const { data } = await api(
        urls.qs("getDepositsHistory", { studentPublicKey, classroomId })
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async getACProfit({
    academicConsultantPublicKey,
    from,
    to,
    ignoreRoleFilter,
  }) {
    try {
      const { data } = await api(
        urls.qs("getACProfit", {
          academicConsultantPublicKey,
          from,
          to,
          ignoreRoleFilter,
        })
      );
      return data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async deleteDeposit({ depositId }) {
    try {
      const { isSuccess } = await api.delete(urls.api("deleteDeposit"), {
        data: { depositId },
      });
      if (isSuccess) message.success(_t("DELETE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async confirmDeposit({ depositId }) {
    try {
      const { isSuccess } = await api.put(urls.api("confirmDeposit"), {
        depositId,
      });
      if (isSuccess) message.success(_t("EDIT_SUCCESS"));
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async confirmedByConsultant(payload) {
    try {
      const { isSuccess } = await api.put(
        urls.qs("confirmedByConsultant"),
        payload
      );
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async confirmedByFinancialAdmin(payload) {
    try {
      const { isSuccess } = await api.put(
        urls.qs("confirmedByFinancialAdmin"),
        payload
      );
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async fixConfirmationOfReservedSessionsOfClassroom({ classroomId }) {
    try {
      const { isSuccess } = await api.put(
        urls.qs("fixConfirmation", { classroomId })
      );
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async fixConfirmationOfReservedSessionsOfClassrooms() {
    try {
      const { isSuccess } = await api.put(
        urls.qs("fixConfirmationOfClassrooms")
      );
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async getDebtorStudents() {
    try {
      const { data } = await api.get(urls.api("debtorStudents"));
      return data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
};
