export default {
  pending_homeworks: "report/getPendingHomeworksReport",
  completed_homeworks: "report/getCompletedHomeworksReport",
  lesson_name_teaching_progress:
    "report/getTeachingProgressReportForNameLesson",
  lesson_title_teaching_progress:
    "report/getTeachingProgressReportForTitleLesson",
  lesson_name_review_progress: "report/getReviewProgressReportForNameLesson",
  lesson_title_review_progress: "report/getReviewProgressReportForTitleLesson",
  student_exams_score: "report/getEachStudentExamsScoreReport",
  student_avg_exams_score: "report/getAvgOfOneStudentExamsScoreReport",
  students_average_exams_score: "report/getAvgOfAllStudentsExamsScoreReport",
  getStudentPresentationReport: "report/GetRollcallOfClassroomsReport",
  getClassroomReportOfStudent: "classroom/getClassroomReportOfStudent",
  //-----------------
  get_tasks_by_public_key: "task/getTaskByPublicKeyForUser",
  //-----------------
  completed_homework_timeline: "report/getCompletedHomeworksTimeLineReport",
  getTotalTeachingHourReport: "report/getTotalTeachingHourReport",
  getEachLevelTotalTeachingHour: "report/getEachLevelTotalTeachingHour",
  getEachLessonTotalTeachingHour: "report/getEachLessonTotalTeachingHour",
};
