module.exports = {
  key: "dashboard",
  dashboard: {
    get_started: "开始",
    simple_way: "不走冤枉路，不花冤枉钱",
    wechat_id: "微信号",
    email: "电子邮件",
    router_title: {
      landing: "着陆",
      report: "报告",
      tasks: "任务",
      class: "课程",
      upcoming: "课表",
      notification: "通知",
      contact_us: "联系我们",
      login: "登录",
    },
  },
  attribute: {},
  placeholder: {},
};
