module.exports = {
  components: {
    app: {
      header: {
        register: "注册",
        login: "登录",
        logout: "注销",
      },
      snackbar: {
        close: "Close",
      },
      page_not_found: {
        title: "404",
        subtitle_part_one: "对不起!",
        subtitle_part_two: "找不到页面",
        buttons: {
          go_back: "返回",
        },
      },
      headerr: {
        dashboard: "主页",
        home: "",
        logout: "注销",
        language: {
          change_language: "改变语言",
          english: "英语",
          chinese: "汉语",
        },
      },
    },
    dashboard: {},
  },
  router: {
    index: {
      panel_title: "Panel",
    },
    routes: {
      panel_routes: {
        dashboard: "Dashboard",
        profile: "Profile",
      },
      page_not_found_routes: {
        not_found: "Page not found",
      },
    },
  },
  views: {},
  layouts: {
    nav_items: {},
  },
  attribute: {
    description: "描述",
    title: "标题",
    examTypes: {
      Total: "总计",
      Pending: "待定",
      TitleExam: "单元考试",
      NameExam: "复习考试",
      SubTitleExam: "复习测试",
      All: "全部",
    },
    Done: "完成",
    Pending: "待定",
    WithOutHomeWork: "没有家庭作业",
    changePassword: "更改密码",
    old_password: "当前密码",
    new_password: "新密码",
    confrim_password: "确认密码",
  },
  validations: {
    required: "{attribute} 不能为空。",
    between:
      "{attribute} Can contain minimum {min} characters and maximum {max} characters.",
    confirmed: "{attribute} 不匹配。",
    digits: "{attribute}必须包含数字，并恰好 {length} 字符。",
    length: "{attribute}必须包含字符，并恰好{length}字符。",
    email: "必须是有效的电子邮件地址。",
    maxValue: "{attribute} 必须 {min} 或少于 {min}",
    max: "{attribute} Cannot be longer than {length}",
    mimes: "{attribute} Must be a valid format.",
    minValue: "{attribute} 必须 {min} 或多于 {min}",
    minLength: "{attribute} 必须 {min} 或多于 {min}",
    min: "{attribute} 必须至少 {length} 字符",
    numeric: "{attribute} 只包含数字",
    decimal: "{attribute} 必须是有效的小数。",
    required_if: "{attribute} Mandatory.",
    sameAsPassword: "{attribute} must be same as password.",
    phone_code: "{attribute} must start with country code",
    url: "{attribute} must start with http:// or https://",
  },

  FAILED_VALIDATION: "Validation for this particular item failed",
  ACCESS_DENIED: "您无权执行当前操作",
  INVALID_GRANT: "Username / password or access token is wrong",
  LOGIN_SUCCESS: "You are logged in successfully.",
  UNAUTHORIZED: "UNAUTHORIZED",
  INVALID_OTP: "Wrong OTP was provided",
  INVALID_PAYLOAD: "Provided payload is invalid",
  INVALID_QUERY: "The requested query parameters can not be used",
  REQUESTS_EXCEEDED: "Hit rate limit; Too many requests",
  ROUTE_NOT_FOUND: "Endpoint does not exist",
  FETCH_DATA_FAILED: "Could not fetch data.",
  CREATE_SUCCESS: "创建成功。",
  UPDATE_SUCCESS: "更新成功。",
  DELETE_SUCCESS: "删除成功。",
  DELETE_FAILED: "Did not delete.",
  ADD_SUCCESS: "添加成功",
  DELETE_MESSAGE: "Are you sure you want to delete this address?",
  NO_RESULT: "没有结果。",
  "Invalid user credentials.": "Invalid user credentials.",
  EVENT_UPDATE_SUCCESSFUL: "项目更新成功",
  FILE_EMPTY: "请选择您的文件",
  SELECT_LESSONS: "请选择课程",
  CHANGE_PASSWORD_HINT: "如果修改此字段，密码将被修改!!",
  FILL_CORRECT: "请填写字段",
  EMPTY_TIME: "请添加时间",
  EMPTY_ITEM: "没有商品",
  SELECT: (ctx) =>
    `请选择${ctx.messages[ctx.locale]["attribute"][ctx.named("name")]}`,
};
