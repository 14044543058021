import api from "@/tools/Api";
import urls from "@/tools/Urls";
import message from "@/tools/Message";
import { _t } from "@/tools/Utils";

export default {
  async createCoursePlanOfStudent(payload) {
    try {
      const { isSuccess } = await api.put(
        urls.api("createCoursePlanOfStudent"),
        payload
      );
      if (isSuccess) {
        message.success(_t("UPDATE_SUCCESS"));
        return isSuccess;
      }
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getStudentCoursePLan({ publicKey }) {
    try {
      let schema = {
        where: [
          [
            {
              column: "publicKey",
              comparison: "Equal",
              operand: publicKey,
            },
          ],
        ],
        order: [
          {
            column: "createMoment",
            descending: true,
          },
        ],
      };

      const { data } = await api.post(urls.api("getStudentCoursePLan"), schema);
      return data.data[0];
    } catch (e) {
      console.log(e);
    }
  },
};
