export default {
  admin_users: "user/all",
  getAcademicConsultants: "user/getAcademicConsultants",
  GetCountOfEachRole: "user/GetCountOfEachRole",
  user_by_base_type: "user/GetAllByRoleAndBaseType",
  admin_lessons: "lesson/getAllLessons",
  admin_specifications: "specification/getAllSpecifications",
  permissions_per_role: "user/getAllPermissionsOfEachRole",
  lessons_title: "lesson/getAllLessonsTitle",
  lessons_names: "lesson/getAllLessonsName",
  create_lesson: "lesson/createLesson",
  delete_lesson: "lesson/deleteLessonById",
  edit_lesson: "lesson/editLesson",
  create_specification: "specification/createSpecification",
  edit_specification: "specification/editSpecification",
  delete_specification: "specification/deleteSpecificationById",
  reset_password: "user/resetPassword",
  uploadCourseFiles: "file/UploadCourseFiles",
  deleteCourseFile: "file/DeleteCourseFile",
  getCourseFile: "file/getCourseFile",
  //
  create_teacher_timetable: "teacherTimeTable/createTeacherTimeTableForTeacher",
  get_teacher_timetable: "teacherTimeTable/getTeacherTimeTableForTeacher",
  //
  create_admin_timetable: "teacherTimeTable/createTeacherTimeTableForAdmin",
  get_admin_timetable: "teacherTimeTable/getTeacherTimeTableForAdmin",
  getTeacherTimetableFiltered:
    "teacherTimeTable/GetTeacherTimeTableFilteredByDate",
  cancel_time: "teacherTimeTable/cancelTime",
  cancel_time_in_classroom: "classroom/CancelTimeInClassroom",
  edit_start_end_time: "teacherTimeTable/editStartOrEndTimeInTimeTable",
  edit_timetable: "teacherTimeTable/EditTimeTable",
  //classes
  admin_classes: "classRoom/getAllClassRooms",
  admin_classes_teacher: "classRoom/getClassroom",
  getFilteredClasses: "classRoom/GetAllClassroomsFilteredByDate",
  not_available_students_times: "classRoom/getNotAvailableTimesOfStudents",
  create_class: "classRoom/createClassRoom",
  get_class_numbers: "classRoom/getAllRoomsNumber",
  not_available_rooms_times: "classRoom/getNotAvailableRoomsAndTimes",
  edit_student_exam: "classroom/editExamDetailOfStudentOfClassroom",
  edit_student_homework: "classroom/editHomeworkDetailOfStudentOfClassroom",
  edit_class_teaching: "classroom/EditTeachingStatesOfClassroom",
  edit_class_review: "classroom/EditReviewStatesOfClassroom",
  edit_class: "classroom/EditClassroom",
  edit_class_times_lessons: "classroom/UpsertTimesToClassroom",
  add_lesson_to_class: "classroom/addLessonToClassroom",
  addSpecificationToClass: "classroom/addSpecificationToClassroom",
  edit_lesson_of_class: "classroom/EditLessonDetailsOfClassroom",
  remove_student_from_class: "classroom/RemoveStudentFromClassroom",
  add_present_information: "classroom/UpsertPresenceInformationOfStudents",
  teaching_day_info: "classroom/UpsertTeachingDayInfoToClassroom",
  remove_time: "teacherTimeTable/RemoveAvailableTimeInTeacherTimeTable",
  classroom_of_user: "classroom/GetClassroomsOfUser",
  edit_time_of_class: "classroom/EditTimeDetailsOfClassroom",
  getClassroomsTimes: "classroom/GetClassroomTimesState",
  get_passed_lessons_of_class: "classroom/GetIdOfLessonsOfPassedSessions",
  disable_classroom: "classroom/deleteClassroom",
  destroy_classroom: "classroom/destroyClassroom",
  getSessionsOfLesson: "classroom/GetSessionsOfLesson",
  deleteLessonFromClass: "classroom/removeLessonFromClassroom",
  removeTimesOfClassroom: "classroom/RemoveAllTimesOfClassroom",
  uploadHomeworkFile: "file/UploadStudentHomeworkFile",
  deleteHomeworkFile: "file/DeleteStudentHomeworkFile",
  getHomeworkFile: "file/getStudentHomeworkFile",
  resetSessionInfo: "classroom/ResetSessionInformation",
  deleteStudentExam: "classroom/RemoveExamOfStudent",
  getBalanceOfStudentsOfClassroom:
    "deposit/GetBalanceInHourOfStudentsOfClassroom",
  addNewHomeworkToStudents: "classroom/AddHomeworkForStudentsOfClassroom",
  archiveClass: "classroom/ArchiveClassroom",
  generateReportForParents: "Classroom/GenerateAndGetOneTimeLinkForSession",
  getSessionReport: "Report/GetSessionReportOfStudent",
  //notifications
  admin_notifications: "notification/GetAllNotificationForAdmin",
  teacher_notifications: "notification/GetAllNotificationForTeacher",
  read_message: "notification/MakeReadMessages",
  createNotification: "notification/CreateCustomNotification",
  //contract
  getContracts: "contract/GetAllContracts",
  addContract: "contract/CreateContract",
  editContract: "contract/EditContract",
  deleteContract: "contract/DeleteContract",
  guideForContract: "contract/getGuideForCreatingContract",
  guideStudentContractBalance: "contract/GetStudentsContractBalance",
  getContractFile: "file/GetContractFile",
  // survey
  getSurvey: "classroom/GetQuestionsOfSurveys",
  setSurvey: "classroom/TakeSurveyOfClassroomSession",
  getSurveyReport: "report/GetEachTeacherSurveyResults",
  //progressStage
  createCoursePlanOfStudent: "StudentCoursePlan/UpsertCoursePlanOfStudent",
  getStudentCoursePLan: "StudentCoursePlan/GetAllStudentsCoursePlan",
  setProgressStageApplication:
    "ProgressStageApplication/UpsertProgressStageApplication",
  getApplications: "ProgressStageApplication/GetAllProgressStageApplication",
  deleteApplication: "ProgressStageApplication/DeleteProgressStageApplication",
  getCoursePlans: "ProgressStageCoursePlan/GetAllProgressStage",
  setProgressStageCoursePlan:
    "ProgressStageCoursePlan/UpsertProgressStageCoursePlan",
  deleteCoursePlan: "ProgressStageCoursePlan/DeleteProgressStageCoursePlan",
  //evaluations test
  getEvaluationTestSpecifications:
    "Specification/GetSpecificationsOfEvaluationTest",
};
