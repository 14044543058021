import api from "@/tools/Api";
import urls from "@/tools/Urls";
import message from "@/tools/Message";
import { _t } from "@/tools/Utils";
import store from "@/store";

export default {
  async getApplications({ studentPublicKey }) {
    try {
      let schema = {
        where: [
          [
            {
              column: "studentPublicKey",
              comparison: "Equal",
              operand: studentPublicKey,
            },
          ],
        ],
        order: [
          {
            column: "createMoment",
            descending: true,
          },
        ],
      };
      const { data } = await api.post(urls.api("getApplications"), schema);
      console.log(data, "application");
      await store.dispatch("application/setApplications", data.data);
    } catch (e) {
      console.log(e);
    }
  },
  async setProgressStageApplication(payload) {
    try {
      const { isSuccess } = await api.post(
        urls.api("setProgressStageApplication"),
        payload
      );
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
      throw e;
    }
  },
  async deleteApplication({ id }) {
    try {
      const { isSuccess } = await api.delete(urls.api("deleteApplication"), {
        data: {
          progressStageApplicationId: id,
        },
      });
      if (isSuccess) message.success(_t("DELETE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      console.log(e);
    }
  },
  async getCoursePlans({ studentPublicKey }) {
    try {
      let schema = {
        where: [
          [
            {
              column: "studentPublicKey",
              comparison: "Equal",
              operand: studentPublicKey,
            },
          ],
        ],
        order: [
          {
            column: "createMoment",
            descending: true,
          },
        ],
      };
      const { data } = await api.post(urls.api("getCoursePlans"), schema);
      console.log(data, "getCoursePlans");
      await store.dispatch("coursePlan/setCoursePlans", data.data);
    } catch (e) {
      console.log(e);
    }
  },
  async setProgressStageCoursePlan(payload) {
    try {
      const { isSuccess } = await api.post(
        urls.api("setProgressStageCoursePlan"),
        payload
      );
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      console.log(e);
    }
  },
  async deleteCoursePlan({ id }) {
    try {
      const { isSuccess } = await api.delete(urls.api("deleteCoursePlan"), {
        data: {
          progressStageCoursePlanId: id,
        },
      });
      if (isSuccess) message.success(_t("DELETE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      console.log(e);
    }
  },
};
