export const event_colors = {
  new: "#FBC02D",
  have_class: "#3060DD",
  waiting_for_class: "#30DD75",
  canceled: "#EF2929",
  full: "#37474F",
  passed: "#a45f4b",
  cancel: "#000000",
  reserved: "#6d28d9",
};
