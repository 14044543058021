import api from "@/tools/Api";
import urls from "@/tools/Urls";
import store from "@/store";
import message from "@/tools/Message";
import { _t } from "@/tools/Utils";

export default {
  async getSettlements({
    page = 1,
    limit = 10,
    search = "",
    all = false,
    dispatch = null,
    role = null,
    fromTo = [],
    teacherPublicKey = null,
  }) {
    try {
      let schema = {
        page: {
          index: page,
          size: limit,
        },
        where: [],
        order: [
          {
            column: "createMoment",
            descending: true,
          },
        ],
      };
      if (search)
        schema.where.push([
          {
            column: "classroomName",
            comparison: "Contains",
            operand: search,
          },
          {
            column: "classroomName",
            comparison: "Contains",
            operand: search.charAt(0).toUpperCase() + search.slice(1),
          },
          {
            column: "classroomName",
            comparison: "Contains",
            operand: search,
          },
          {
            column: "classroomName",
            comparison: "Contains",
            operand: search.charAt(0).toUpperCase() + search.slice(1),
          },
        ]);
      if (fromTo.length) {
        schema.where.push([
          {
            column: "fromTime",
            comparison: "Equal",
            operand: new Date(fromTo[0]).getTime() / 1000,
          },
          {
            column: "toTime",
            comparison: "Equal",
            operand: new Date(fromTo[1]).getTime() / 1000,
          },
        ]);
      }
      if (teacherPublicKey) {
        schema.where.push([
          {
            column: "teacherPublicKey",
            comparison: "Equal",
            operand: teacherPublicKey,
          },
        ]);
      }
      if (all) {
        schema.page.index = 1;
        schema.page.size = -1;
      }
      const { data } = await api.post(
        urls.api(
          role === "Teacher" ? "get_settlements" : "get_settlements_all"
        ),
        schema
      );
      console.log(data.data, "settlements");
      if (dispatch) {
        await store.dispatch(`${dispatch}`, data.data);
      }
      return {
        settlements: data.data,
        pageCount: Math.ceil(data.totalCount / limit),
      };
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async addSalaryToTeacher(payload) {
    try {
      const { data } = await api.post(urls.api("create_settlement"), payload);
      if (data) {
        message.success(_t("CREATE_SUCCESS"));
        return data;
      }
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async editSalaryToTeacher(payload) {
    try {
      const { data } = await api.put(urls.api("edit_settlement"), payload);
      if (data) {
        message.success(_t("CREATE_SUCCESS"));
        return data;
      }
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getSumOfTeacherSettlement({ publicKey = null }) {
    try {
      const { data } = await api.get(
        urls.qs("get_sum_teacher_settlement", { publicKey })
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async getSumOfAllTeachersSettlement() {
    try {
      const { data } = await api.get(urls.qs("get_sum_all_teacher_settlement"));
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async getGuideForCreateSettlement({
    teacherPublicKey,
    toTime,
    teacherLessonType,
  }) {
    try {
      const { data } = await api(
        urls.qs("getGuideForCreateSettlement", {
          teacherPublicKey,
          toTime,
          teacherLessonType,
        })
      );
      console.log(data, "getGuideForCreateSettlement");
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async calcClassroomBalance({
    teacherPublicKey,
    toTime,
    amount,
    teacherLessonType,
  }) {
    try {
      const { data } = await api(
        urls.qs("calcClassroomBalance", {
          teacherPublicKey,
          toTime,
          amount,
          teacherLessonType,
        })
      );
      console.log(data, "calcClassroomBalance");
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async getTeacherSettlementsReport({ teacherPublicKey }) {
    try {
      const { data } = await api(
        urls.qs("getTeacherSettlementsReport", { teacherPublicKey })
      );
      return data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async getSettlementHistory({ teacherPublicKey = null }) {
    try {
      const { data } = await api(
        urls.qs("getSettlementHistory", { teacherPublicKey })
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async deleteSettlement({ settlementId }) {
    try {
      const { isSuccess } = await api.delete(urls.api("deleteSettlement"), {
        data: { settlementId },
      });
      if (isSuccess) message.success(_t("DELETE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      console.log(e);
    }
  },
  async getGuideForGroupCreateSettlement(payload) {
    try {
      const { data } = await api.post(
        urls.qs("getGuideForGroupCreateSettlement"),
        payload
      );
      console.log(data);
      return data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async createGroupSettlement(payload) {
    try {
      const { isSuccess } = await api.post(
        urls.api("createGroupSettlement"),
        payload
      );
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));

      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async getTeacherMonthlyHours({ teacherPublicKey }) {
    try {
      const { data } = await api.get(
        urls.qs("getTeacherMonthlyHours", { teacherPublicKey })
      );
      return data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
};
