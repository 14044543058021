"use strict";
import { _t } from "@/tools/Utils";

export default [
  {
    path: "/admin/panel",
    component: () => import("@/packages/admin/layout/Admin.layout.vue"),
    redirect: { name: "admin.dashboard" },
    meta: {
      title: _t("admin.route_title.dashboard"),
      requiresAdmin: true,
    },
    children: [
      {
        path: "/admin/panel/dashboard/",
        name: "admin.dashboard",
        component: () => import("@/packages/admin/views/dashboard.vue"),
        meta: {
          title: _t("admin.route_title.dashboard"),
          requiresAdmin: true,
        },
      },
      {
        path: "/admin/panel/users",
        name: "admin.users",
        component: () => import("@/packages/admin/views/users.vue"),
        meta: {
          title: _t("admin.route_title.users"),
          requiresAdmin: true,
        },
      },
      {
        path: "/admin/panel/users/report/:key",
        name: "admin.users.report",
        component: () => import("@/packages/admin/views/userReports.vue"),
        meta: {
          title: _t("admin.route_title.user_reports"),
          requiresAdmin: true,
        },
      },
      {
        path: "/admin/panel/users/progressStage/:id",
        name: "admin.users.progress",
        component: () => import("@/packages/admin/views/progressStage.vue"),
        meta: {
          title: _t("admin.route_title.progressStage"),
          requiresAdmin: true,
        },
      },
      {
        path: "/admin/panel/lessons",
        name: "admin.lessons",
        component: () => import("@/packages/admin/views/lessons.vue"),
        meta: {
          title: _t("admin.route_title.lessons"),
          requiresAdmin: true,
        },
      },
      {
        path: "/admin/panel/courses",
        name: "admin.courses",
        component: () => import("@/packages/admin/views/specifications.vue"),
        meta: {
          title: _t("admin.route_title.courses"),
          requiresAdmin: true,
        },
      },
      {
        path: "/admin/panel/reports/:key?",
        name: "admin.reports",
        component: () => import("@/packages/admin/views/reports.vue"),
        meta: {
          title: _t("admin.route_title.reports"),
          requiresAdmin: true,
        },
      },
      {
        path: "/admin/panel/adminSchedule",
        name: "admin.schedule",
        component: () => import("@/packages/admin/views/schedule.vue"),
        meta: {
          title: _t("admin.route_title.schedule"),
          requiresAdmin: true,
        },
      },
      {
        path: "/admin/panel/teacherSchedule",
        name: "teacher.schedule",
        component: () => import("@/packages/admin/views/schedule.vue"),
        meta: {
          title: _t("admin.route_title.schedule"),
          requiresAdmin: true,
        },
      },
      {
        path: "/admin/panel/class/table",
        name: "admin.class_room",
        component: () => import("@/packages/admin/views/classRoom.vue"),
        meta: {
          title: _t("admin.route_title.class_room"),
          requiresAdmin: true,
        },
      },
      {
        path: "/admin/panel/class/upcoming",
        name: "admin.class_upcoming",
        component: () => import("@/packages/admin/views/classRoomUpcoming.vue"),
        meta: {
          title: _t("admin.route_title.class_room"),
          requiresAdmin: true,
        },
      },
      {
        path: "/admin/panel/class/create",
        name: "admin.class_room_create",
        component: () => import("@/packages/admin/views/classRoomCreate.vue"),
        meta: {
          title: _t("admin.route_title.class_room_create"),
          requiresAdmin: true,
        },
      },
      {
        path: "/admin/panel/class/:id",
        redirect: { name: "admin.class_details" },
        component: () => import("@/packages/admin/layout/ClassLayout"),
        children: [
          {
            path: "",
            name: "admin.class_details",
            component: () => import("@/packages/admin/views/classDetails.vue"),
            meta: {
              title: _t("admin.route_title.class_details"),
              requiresAdmin: true,
            },
          },
          {
            path: "student/:student_id/exams-homeworks",
            name: "admin.class_details_exams",
            component: () =>
              import(
                "@/packages/admin/views/classDetailsStudentExamsAndHomeworks.vue"
              ),
            meta: {
              title: _t("admin.route_title.class_details"),
              requiresAdmin: true,
            },
          },
        ],
        meta: {
          title: _t("admin.route_title.class_details"),
          requiresAdmin: true,
        },
      },
      {
        path: "/admin/panel/notifications",
        name: "admin.notifications",
        component: () => import("@/packages/admin/views/notifications.vue"),
        meta: {
          title: _t("admin.route_title.notifications"),
          requiresAdmin: true,
        },
      },
      {
        path: "/admin/panel/contract",
        name: "admin.contract",
        component: () => import("@/packages/admin/views/contract.vue"),
        meta: {
          title: _t("admin.route_title.contract"),
          requiresAdmin: true,
        },
      },
    ],
  },
  {
    path: "/parent/report",
    name: "parent",
    meta: {
      title: "Parent",
      headTags: [],
    },
    redirect: { name: "parent.report" },
    component: () => import("@/layouts/App.vue"),
    children: [
      {
        path: "/parent/report/:reportId",
        name: "parent-report",
        component: () => import("@/packages/admin/views/ParentReport.vue"),
        meta: {
          title: "Report",
        },
      },
    ],
  },
];
