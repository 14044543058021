import moment from "moment";
import i18n from "@/plugins/i18n";

export default {
  install(Vue) {
    // an instance method
    Vue.prototype.$_date = function (date, type = "short") {
      let locale = i18n.locale === "en" ? "en" : "zh-cn";
      let format_type = locale === "en" ? "ddd[,] Do MMM" : "ddd[,] MMM Do";
      if (type === "fromNow") return moment(date).locale(locale).fromNow();
      if (type === "long") format_type = "YYYY-MM-DD, h:mm a";
      if (type === "hour") format_type = "H:MM";
      if (type === "month") format_type = "MMMM";
      if (type === "ISO") format_type = "YYYY-MM-DD";
      if (type === "YM") format_type = "YYYY-MM";
      if (type === "day") format_type = "dddd";
      return moment(date).locale(locale).format(format_type);
    };
  },
};
