// initial state

import urls from "@/tools/Urls";
import api from "@/tools/Api";

const state = () => ({
  classes: [],
  classroomNumbers: [],
  studentEvents: [],
  notAvailableTimes: [],
  teacherEvents: [],
  lessons: [],
  selectedStudents: [],
  teacher: null,
  specification: [],
  times: [],
  subTime: null,
  //details
  student: null,
  class: null,
  baseType: null,
  classrooms: [],
  classTimes: [],
  passedLessons: [],
});

// getters
const getters = {
  get_classes: (state) => state.classes,
  getStudentEvents: (state) => [
    ...state.notAvailableTimes,
    ...state.studentEvents,
  ],
  classroomNumbers: (state) => state.classroomNumbers,
  getTeacherEvents: (state) => [...state.teacherEvents, ...state.classTimes],
  teacherEvents: (state) => state.teacherEvents,
  getLessons: (state) => state.lessons,
  getTeacher: (state) => state.teacher,
  getSpecification: (state) => state.specification,
  getSelectedStudents: (state) => state.selectedStudents,
  getTimes: (state) => state.times,
  classTimes: (state) => state.classTimes,
  getTimesAvailableTimeId: (state) => {
    return state.times.map((time) => time.availableTimeId);
  },
  getFormValid: (state) => {
    if (!state.selectedStudents.length) return "Please select a student";
    if (!state.teacher) return "Please select a teacher";
    // if (!state.times.length) return "Please select a available time";
    // if (!state.specification.length) return "Please select a course";
    // if (!state.lessons.length) return "Please select some lessons";
    return null;
  },
  getForm: (state) => {
    // const times = state.times.map((t) => {
    //   const { availableTimeId, classroomNumber } = t;
    //   return { availableTimeId, classroomNumber, message: null };
    // });
    const specifications = state.specification.map((s) => s.specificationId);
    const lessons = state.lessons.map((l) => {
      const { name, title, subTitle, level, teachingTimeInHour, description } =
        l;
      return {
        lessonName: name,
        lessonTitle: title,
        lessonSubTitle: subTitle,
        lessonLevel: level,
        teachingLessonTimeInHour: teachingTimeInHour,
        description: description,
      };
    });
    return {
      studentsPublicKey: state.selectedStudents.map((s) => s.publicKey),
      lessons,
      specificationIds: specifications,
      teacherPublicKey: state.teacher,
      // times,
    };
  },
  getClass: (state) => state.class,
  getStudents: (state) => state.class?.students || null,
  getBaseType: (state) => state.baseType,
  classrooms: (state) => state.classrooms,
  getSubTime: (state) => state.subTime,
  student: (state) => state.student,
  //
  passedLessons: (state) => state.passedLessons,
};

// mutations
const mutations = {
  clearForm(state) {
    //state.times = [];
    //state.subTime = null;
    //state.selectedStudents = [];
    //state.teacher = null;
    state.specification = [];
    state.lessons = [];
    //state.baseType = null;
    //state.teacherEvents = [];
    //state.classTimes = [];
    //state.studentEvents = [];
    //state.notAvailableTimes = [];
  },
  updateClasses(state, payload) {
    state.classes = payload;
  },
  updateClassTimes(state, payload) {
    state.classTimes = payload;
  },
  updateStudentEvents(state, payload) {
    state.studentEvents = payload;
  },
  setStudent(state, payload) {
    state.student = payload;
  },
  updateTeacherEvents(state, payload) {
    state.teacherEvents = payload;
  },
  updateLessons(state, payload) {
    state.lessons = payload;
  },
  updateTeacher(state, payload) {
    state.teacher = payload;
  },
  updateSpecification(state, payload) {
    state.specification = payload;
  },
  updateTeachingHour(state, payload) {
    console.log(payload);
    state.lessons = state.lessons.map((l) =>
      l.lessonId === payload.lessonId
        ? {
            ...payload,
            teachingTimeInHour: parseFloat(payload.teachingTimeInHour),
          }
        : l
    );
    console.log(state.lessons);
  },
  deleteLesson(state, index) {
    state.lessons.splice(index, 1);
  },
  updateSelectedStudents(state, payload) {
    state.selectedStudents = payload;
  },
  updateTimes(state, payload) {
    state.times = [...payload];
  },
  clearTimes(state) {
    state.times = [];
  },
  //setNotAvailableTimes
  setNotAvailableTimes(state, payload) {
    state.notAvailableTimes = payload;
  },
  updateClass(state, payload) {
    state.class = payload;
  },
  setBaseType(state, payload) {
    state.baseType = payload;
  },
  setClassroomsOfUser(state, payload) {
    state.classrooms = payload;
  },
  setClassroomNumbers(state, payload) {
    state.classroomNumbers = payload;
  },
  setPassedLessons(state, payload) {
    state.passedLessons = payload;
  },
};

// actions
const actions = {
  setClasses({ commit }, payload) {
    commit("updateClasses", payload);
  },
  setClassTimes({ commit }, payload) {
    commit("updateClassTimes", payload);
  },
  setTeacherEvents({ commit }, payload) {
    commit("updateTeacherEvents", payload);
  },
  setStudentsEvents({ commit }, payload) {
    commit("updateStudentEvents", payload);
  },
  setLessons({ commit }, payload) {
    commit("updateLessons", payload);
  },
  setTeacher({ commit }, payload) {
    commit("updateTeacher", payload);
  },
  setNotAvailableTimes({ commit }, payload) {
    commit("setNotAvailableTimes", payload);
  },
  setClass({ commit }, payload) {
    commit("updateClass", payload);
  },
  setClassroomsOfUser({ commit }, payload) {
    commit("setClassroomsOfUser", payload);
  },
  setClassroomNumbers({ commit }, payload) {
    commit("setClassroomNumbers", payload);
  },
  setPassedLessons({ commit }, payload) {
    commit("setPassedLessons", payload);
  },
  async getGlobalReportClasses(
    { commit },
    { search = null, role = null, page = 1, limit = 10, all = false }
  ) {
    let schema = {
      page: {
        index: page,
        size: limit,
      },
      where: [],
      order: [
        {
          column: "createMoment",
          descending: true,
        },
      ],
    };
    schema.where.push([
      {
        column: "state",
        comparison: "NotEqual",
        operand: "Deleted",
      },
    ]);
    if (search)
      schema.where.push([
        {
          column: "classroomName",
          comparison: "Contains",
          operand: search,
        },
        {
          column: "classroomName",
          comparison: "Contains",
          operand: search.charAt(0).toUpperCase() + search.slice(1),
        },
        {
          column: "classroomName",
          comparison: "Contains",
          operand: search.toLowerCase(),
        },
      ]);
    if (all) {
      schema.page.index = 1;
      schema.page.size = -1;
    }
    const url =
      role === "Teacher"
        ? urls.api("admin_classes_teacher")
        : urls.qs("admin_classes");

    const { data } = await api.post(url, schema);
    commit("setClassroomsOfUser", data.data);
  },
};

export default {
  namespaced: true,
  name: "class",
  state,
  getters,
  actions,
  mutations,
};
