export default {
  /*Auth Routes********************/
  auth_login: "user/login",
  auth_register: "user/register",
  auth_delete: "user/deleteUserByPublicKey",
  edit_user: "user/editUser",
  changePassword: "user/ManualChangePassword",
  renewToken: "user/renew",
  request_to_verify: "user/requestmoblieverify",
  verify_code: "user/verify",
  getLoginHistory: "user/getUsersLoginDatesHistory",
  archiveUser: "user/ArchiveUser",
  /*User Routes********************/
  is_admin: "user/isadmin",
  user_me: "user/getuser",
  one_time_pass: "user/oneTimePassword",
};
