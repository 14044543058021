import api from "@/tools/Api";
import urls from "@/tools/Urls";
import message from "@/tools/Message";
import { _t } from "@/tools/Utils";

export default {
  async getSurveyQuestions() {
    try {
      const { data } = await api(urls.api("getSurvey"));
      return data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
      throw e;
    }
  },
  async submitStudentSurvey(payload) {
    try {
      const { isSuccess } = await api.put(urls.api("setSurvey"), payload);
      if (isSuccess) message.success(_t("CREATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
      throw e;
    }
  },
  async getSurveyReport(teacherPublicKey = null, classroomId = null) {
    try {
      const { data } = await api(
        urls.qs("getSurveyReport", { teacherPublicKey, classroomId })
      );
      console.log(data, "getSurveyReport");
      return data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
      throw e;
    }
  },
};
