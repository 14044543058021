module.exports = {
  key: "finance",
  finance: {
    router_title: {
      deposit: "Deposit",
      settlement: "Settlement",
      transfer: "Transfer",
      teacherFee: "Fee",
    },
  },
  attribute: {
    transfer: "Transfer",
    deposits: "Deposits",
    deposit: "Deposit",
    teacherFee: "Teacher fee",
    settlement: "Settlement",
    settlements: "Settlements",
    amount: "Amount",
    createMoment: "Created at",
    submitMoment: "Submitted at",
    chargeAt: "Charged at",
    addDeposit: "Add deposit",
    addSettlement: "Add settlement",
    editSettlement: "Edit settlement",
    editDeposit: "Edit deposit",
    classes: "Classes",
    student: "Student",
    sum: "Sum",
    Students: "Students",
    Teachers: "Teachers",
    wallet: "Wallet",
    addSalary: "Calculate salary",
    fromTime: "From",
    toTime: "To",
    balances: "Balances",
    remaningHour: "Remaining hour",
    totalUsed: "Total used",
    totalPay: "Total pay",
    salarySum: "Salary summation",
    sumOfTeacherSettlements: "Sum of teacher settlements",
    sumOfAllSettlements: "Sum of all Settlements",
    settlementsOfClass: "Settlements of class",
    teacherTotalGained: "Teacher total salary",
    class_empty: "There is no class!",
    remaningChargeInHour: "Remaining charge",
    confirmMessage: "Confirm message",
    costPerHour: "Cost per hour",
    shouldCharge: "Should charge",
    shouldPay: "Should pay",
    totalPayHour: "Total pay hour",
    teachHour: "Teaching hour",
    monthlyTeaching: "Monthly teaching",
    totalMonthlyTaught: "Total monthly taught",
    teachFee: "Teaching fee",
    lowAmount: "The amount deposited by students in this class is not enough",
    shouldChargeInHour: "Should charge",
    find: "Find",
    fromTo: "from:to",
    settlementType: "Settlement type",
    settlementDescription: "Description",
    classroomTotalCost: "Class total cost",
    totalGained: "Total gained",
    totalTeachHour: "Total taught",
    studentsTotalPayForToTime: "Students total pay",
    classroomsDetail: "Class detail",
    studentsTotalPayForClassroom: "Students total pay",
    report: "Report",
    totalPayedAmount: "Total payed amount",
    totalShouldPayToTeacher: "Total should pay to teacher",
    shouldPayToTeacher: "Should pay to teacher",
    salaryHistory: "History & sum",
    classroomStudents: "Enrolled students",
    payedAmount: "Payed amount",
    incomeInHour: "Income/h",
    charge: "Charge/h",
    exportAll: "Export all",
    teachingSalary: "Teaching salary",
    otherPayments: "Other payments",
    TeachingSalary: "Teaching salary",
    OtherPayments: "Other payments",
    academicConsultantPublicKey: "Academic consultant",
    ACProfit: "Academic consultant profit",
    profit: "Contract value",
    show: "Show",
    depositType: "Deposit type",
    selectClass: "Please select a class",
    selectContract: "Please select a contract",
    discountValue: "Discount value",
    id: "ID",
    continue: "Continue",
    continue_sure: "Are you sure want to continue?",
    transferBalance: "Transfer balance",
    addTransfer: "Create transfer",
    sourceDepositId: "Source deposit",
    destinationDepositId: "Destination deposit",
    transfers: "Transfers",
    sourceClassroomName: "Source classroom name",
    destinationClassroomName: "Destination classroom name",
    editTransfer: "Edit transfer",
    contractFile: "Contract file",
    confirmedBy: "Confirmed by",
    nobody: "Nobody",
    ACConfirmation: "Academic consultant confirmation",
    FAConfirmation: "Financial admin confirmation",
    FAMessage: "Financial admin message",
    ACMessage: "Academic consultant message",
    teacherLessonType: "Lesson type",
    changeConsultant: "Change consultant",
    academicConsultants: "Consultants",
    teacherName: "Teacher name",
    destinationClassroom: "Destination classroom",
    sourceClassroom: "Source classroom",
    overallStudentsBalance: "Overall students balance",
    isConfirmed: "Confirmation state",
    confirmed: "Confirmed",
    notConfirmed: "Not confirmed",
    debtorStudents: "Debtor students",
    studentFullName: "Student name",
    reservedSessionCount: "Reserved sessions",
    teacherFullName: "Teacher full name",
    addGroupSalary: "Add group salary",
    totalTeachHours: "Total teaching hours",
    totalMonthlyTeachingHour: "Total monthly teaching",
    monthly: "Monthly",
    fee: "Fee",
    numberOfStudentsInAClass: "Number of students in a class",
    GCSE_Online: "GCSE online",
    GCSE_Offline: "GCSE offline",
    A_LEVEL_Online: "A-LEVEL online",
    A_LEVEL_Offline: "A-LEVEL offline",
    KS_3_Online: "KS-3 online",
    KS_3_Offline: "KS-3 offline",
    English_Elementary_Online: "English elementary online",
    English_Elementary_Offline: "English elementary offline",
    AdditionalExamination_Online: "AdditionalExamination online",
    AdditionalExamination_Offline: "AdditionalExamination offline",
    University_Online: "University online",
    University_Offline: "University offline",
    Contest_Online: "Contest online",
    Contest_Offline: "Contest offline",
    IELTS_Online: "IELTS online",
    IELTS_Offline: "IELTS offline",
    IB_Online: "IB online",
    IB_Offline: "IB offline",
    AP_Online: "AP online",
    AP_Offline: "AP offline",
    TOEFL_Online: "TOEFL online",
    TOEFL_Offline: "TOEFL offline",
    "GCSE | Online": "GCSE | Online",
    "GCSE | Offline": "GCSE | Offline",
    "A_LEVEL | Online": "A-LEVEL | Online",
    "A_LEVEL | Offline": "A-LEVEL | Offline",
    "KS_3 | Online": "KS-3 | Online",
    "KS_3 | Offline": "KS-3 | Offline",
    "English_Elementary | Online": "English elementary | Online",
    "English_Elementary | Offline": "English elementary | Offline",
    "AdditionalExamination | Online": "Additional examination | Online",
    "AdditionalExamination | Offline": "Additional examination | Offline",
    "University | Online": "University | Online",
    "University | Offline": "University | Offline",
    "Contest | Online": "Contest | Online",
    "Contest | Offline": "Contest | Offline",
    "IELTS | Online": "IELTS | Online",
    "IELTS | Offline": "IELTS | Offline",
    "IB | Online": "IB | Online",
    "IB | Offline": "IB | Offline",
    "AP | Online": "AP | Online",
    "AP | Offline": "AP | Offline",
    "TOEFL | Online": "TOEFL | Online",
    "TOEFL | Offline": "TOEFL | Offline",
    sideCost: "Side cost",
    ClassroomSideCosts: "Classroom side const",
    Contract: "Contract",
    shouldPayForSideCosts: "Should pay for side costs",
    sideCosts: "Side costs",
    totalShouldPayForSideCosts: "Total should pay for side costs",
    totalPaidForSideCosts: "Total paid for side costs",
    sideCostsBalance: "Side costs balance",
    sourceClassroomTeacherFullName: "Source classroom teacher",
    sourceClassroomCharge: "Source classroom charge/Hour",
    sourceClassroomIncomeInHour: "Source classroom Income/Yuan",
    destinationClassroomTeacherFullName: "Destination classroom teacher",
    destinationClassroomCharge: "Destination classroom charge/Hour",
    destinationClassroomIncomeInHour: "Destination classroom Income/Yuan",
    confirmationDate: "Confirmation date",
  },
};
