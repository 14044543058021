<template>
  <PageNotFound></PageNotFound>
</template>

<script>
import PageNotFound from "@/components/app/PageNotFound";
export default {
  name: "Page404",
  components: { PageNotFound },
};
</script>

<style scoped></style>
