module.exports = {
  key: "dashboard",
  dashboard: {
    get_started: "Get started",
    simple_way: "Wise choice, worthy cost",
    wechat_id: "Wechat ID",
    email: "Email",
    router_title: {
      landing: "Landing",
      contact_us: "Contact us",
      login: "Login",
      report: "Report",
      tasks: "Tasks",
      class: "Class",
      upcoming: "Upcoming",
      notification: "Notification",
    },
  },
  attribute: {},
  placeholder: {},
};
