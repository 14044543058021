import api from "@/tools/Api";
import urls from "@/tools/Urls";
import message from "@/tools/Message";
import store from "@/store";

export default {
  async getSpecifications({
    page = 1,
    limit = 10,
    level = "",
    search = "",
    all = false,
    baseType = "",
    dispatch = null,
  }) {
    try {
      let schema = {
        page: {
          index: page,
          size: limit,
        },
        where: [],
        order: [
          {
            column: "createMoment",
            descending: true,
          },
        ],
      };
      if (search)
        schema.where.push([
          {
            column: "title",
            comparison: "Contains",
            operand: search,
          },
          {
            column: "title",
            comparison: "Contains",
            operand: search.charAt(0).toUpperCase() + search.slice(1),
          },
        ]);
      if (level) {
        schema.where.push([
          {
            column: "level",
            comparison: "Equal",
            operand: level,
          },
        ]);
      }
      if (baseType) {
        schema.where.push([
          {
            column: "baseType",
            comparison: "Equal",
            operand: baseType,
          },
        ]);
      }
      if (all) {
        schema.page.index = 1;
        schema.page.size = -1;
      }
      const { data } = await api.post(urls.api("admin_specifications"), schema);
      if (dispatch) {
        await store.dispatch(`${dispatch}`, data.data);
      }
      return {
        specifications: data.data,
        pageCount: data.pageCount,
      };
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async createSpecification(payload) {
    try {
      const res = await api.post(urls.api("create_specification"), payload);
      if (res.data) message.success("Specification created successfully");
      return res.data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async editSpecification(payload) {
    try {
      const res = await api.put(urls.api("edit_specification"), payload);
      if (res.data) message.success("Specification edited successfully");
      return res.data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async deleteSpecification(key) {
    try {
      const res = await api.delete(urls.api("delete_specification"), {
        data: {
          specificationId: key,
        },
      });
      if (res.data) message.success("Specification deleted successfully");
    } catch (e) {
      console.log(e.response.data);
      message.error(e.response.data.Message);
    }
  },
  async uploadCourseFiles(files, names) {
    try {
      const { data } = await api.post(
        urls.qs("uploadCourseFiles", { names }),
        files
      );
      return data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
      throw e;
    }
  },
  async deleteCourseFile(fileName) {
    try {
      const { data } = await api.delete(
        urls.qs("deleteCourseFile") + `/${fileName}`
      );
      return data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
      throw e;
    }
  },
  async getEvaluationTestSpecifications(list, baseType) {
    try {
      const { data } = await api.post(
        urls.api("getEvaluationTestSpecifications"),
        { studentsPublicKey: list, baseType }
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  },
};
