"use strict";

import { _t } from "@/tools/Utils";

export default [
  {
    path: "/admin/finance",
    name: "finance",
    component: () => import("@/packages/admin/layout/Admin.layout"),
    meta: {
      requiresAdmin: true,
      title: _t("finance.router_title.finance"),
    },
    redirect: { name: "finance.deposit" },
    children: [
      {
        path: "deposit",
        name: "finance.deposit",
        component: () => import("@/packages/finance/views/deposit"),
        meta: {
          title: _t("finance.router_title.deposit"),
          headTags: [],
        },
      },
      {
        path: "settlement",
        name: "finance.settlement",
        component: () => import("@/packages/finance/views/settlement"),
        meta: {
          title: _t("finance.router_title.settlement"),
          headTags: [],
        },
      },
      {
        path: "transfer",
        name: "finance.transfer",
        component: () => import("@/packages/finance/views/transfer"),
        meta: {
          title: _t("finance.router_title.transfer"),
          headTags: [],
        },
      },
      {
        path: "fee",
        name: "finance.teacherFee",
        component: () => import("@/packages/finance/views/teacherFee"),
        meta: {
          title: _t("finance.router_title.teacherFee"),
          headTags: [],
        },
      },
    ],
  },
];
