// initial state

const state = () => ({
  year: new Date().getFullYear().toString(),
  month: null,
  level: null,
});

// getters
const getters = {
  year: (state) => state.year,
  month: (state) => state.month,
  level: (state) => state.level,
};

// mutations
const mutations = {
  updateYear(state, payload) {
    state.year = payload;
  },
  updateMonth(state, payload) {
    state.month = payload;
  },
  updateLevel(state, payload) {
    state.level = payload;
  },
};

// actions
const actions = {};

export default {
  namespaced: true,
  name: "report",
  state,
  getters,
  actions,
  mutations,
};
