import message from "@/tools/Message";
import api from "@/tools/Api";
import urls from "@/tools/Urls";

export default {
  async addStudentAcademicCost(payload) {
    try {
      const { isSuccess } = await api.put(
        urls.api("add_student_academic_cost"),
        payload
      );
      if (isSuccess) {
        message.success("Added successfully");
        return true;
      }
      return false;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async addTeacherSalary(payload) {
    try {
      const { data } = await api.put(urls.api("add_teacher_salary"), payload);
      if (data.isSuccess) message.success("Added successfully");
      return true;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async addStudentSideCost(payload) {
    try {
      const { isSuccess } = await api.put(
        urls.api("add_student_side_cost"),
        payload
      );
      if (isSuccess) {
        message.success("Update successfully");
        return true;
      }
      return false;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
};
