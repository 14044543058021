"use strict";

import { _t } from "@/tools/Utils";

export default [
  {
    path: "/panel/reports",
    name: "report",
    redirect: { name: "report.main" },
    component: () => import("@/packages/Reports/layouts/ReportLayout.vue"),
    meta: {
      requiresAuth: true,
      title: _t("dashboard.router_title.report"),
    },
    children: [
      {
        path: "/panel/reports",
        name: "report.main",
        component: () => import("@/packages/Reports/views/report.vue"),
        meta: {
          requiresAuth: true,
          title: _t("dashboard.router_title.report"),
        },
      },
      {
        path: "/panel/classes",
        name: "report.class",
        component: () => import("@/packages/Reports/views/class.vue"),
        meta: {
          requiresAuth: true,
          title: _t("dashboard.router_title.class"),
        },
      },
      {
        path: "/panel/progressStage",
        name: "report.progressStage",
        component: () => import("@/packages/Reports/views/ProgressStage.vue"),
        meta: {
          requiresAuth: true,
          title: _t("dashboard.router_title.class"),
        },
      },
      {
        path: "/panel/upcoming",
        name: "report.upcoming",
        component: () => import("@/packages/Reports/views/upcoming"),
        meta: {
          requiresAuth: true,
          title: _t("dashboard.router_title.upcoming"),
        },
      },{
        path: "/panel/notifications",
        name: "report.notifications",
        component: () => import("@/packages/Reports/views/stuentNotification"),
        meta: {
          requiresAuth: true,
          title: _t("dashboard.router_title.notification"),
        },
      },
    ],
  },
];
