module.exports = {
  key: "authentication",

  authentication: {
    components: {
      auth: {
        welcome: "欢迎回来",
        title: "登入你的帐户",
        buttons: {
          submit: "提交",
          login: "登录",
        },
      },
      register: {
        title: "注册",
        buttons: {
          submit: "提交",
        },
      },
    },
    router_title: {
      login: "登录",
      register: "注册",
      forget_password: "忘记密码",
      contact_us: "联系我们",
    },
  },
  attribute: {
    userName: "用户名",
    password: "密码",
    fullName: "全名",
    nickName: "英文名",
    permissions: "权限",
    additionalPermissions: "附加权限",
    confirm_password: "确认密码",
  },
  placeholder: {},
};
