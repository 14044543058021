// initial state

const state = () => ({
  contracts: [],
});

// getters
const getters = {
  contracts: (state) => state.contracts,
};

// mutations
const mutations = {
  setContracts(state, payload) {
    state.contracts = payload;
  },
};

// actions
const actions = {
  setContracts({ commit }, payload) {
    commit("setContracts", payload);
  },
};

export default {
  namespaced: true,
  name: "contract",
  state,
  getters,
  actions,
  mutations,
};
