// initial state

const state = () => ({
  notifications: [],
  notification: {},
  modal: false,
});

// getters
const getters = {
  notifications: (state) => state.notifications,
  notification: (state) => state.notification,
  modal: (state) => state.modal,
};

// mutations
const mutations = {
  setNotifications(state, data) {
    state.notifications = data;
  },
  setNotification(state, data) {
    state.notification = data;
  },
  setModal(state, payload) {
    state.modal = payload;
  },
};

// actions
const actions = {
  setNotifications({ commit }, payload) {
    commit("setNotifications", payload);
  },
};

export default {
  namespaced: true,
  name: "notification",
  state,
  getters,
  actions,
  mutations,
};
