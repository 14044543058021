import moment from "moment";

export default function (app) {
  app.mixin({
    methods: {
      isFormValidate() {
        this.$v.$touch();
        return !this.$v.$invalid;
      },
      getIdWithName(array, name) {
        return array.findIndex((item) => item.id === name);
      },
      truncateString(str, num) {
        if (str.length > num) {
          return str.slice(0, num) + "...";
        } else {
          return str;
        }
      },
      generateId() {
        return Math.floor(Math.random() * 1000 + Date.now());
      },
      getMonthDateRange(year, month) {
        // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
        // array is 'year', 'month', 'day', etc
        const startDate = moment([year, month - 1]);

        // Clone the value before .endOf()
        const endDate = moment(startDate).endOf("month");

        // just for demonstration:
        // console.log(startDate.toDate());
        // console.log(startDate.format());
        // console.log(startDate.unix());
        //
        // console.log(endDate.toDate());
        // console.log(endDate.format());
        // console.log(endDate.unix());

        // make sure to call toDate() for plain JavaScript date type
        return { start: startDate, end: endDate };
      },
    },
  });
}
