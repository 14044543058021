// initial state

const state = () => ({
  coursePlans: [],
  coursePlan: null,
  appReadOnly: false,
});

// getters
const getters = {
  coursePlans: (state) => state.coursePlans,
  coursePlan: (state) => state.coursePlan,
  appReadOnly: (state) => state.appReadOnly,
};

// mutations
const mutations = {
  setCoursePlans(state, payload) {
    state.coursePlans = payload;
  },
  setCoursePlan(state, payload) {
    state.coursePlan = payload;
  },
  setAppReadOnly(state, payload) {
    state.appReadOnly = payload;
  },
};

// actions
const actions = {
  setCoursePlans({ commit }, payload) {
    commit("setCoursePlans", payload);
  },
};

export default {
  namespaced: true,
  name: "coursePlan",
  state,
  getters,
  actions,
  mutations,
};
