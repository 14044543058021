// initial state

const state = () => ({
  users: [],
  user: null,
  teachers: [],
  consultants: [],
  lessons: [],
  specifications: [],
  tasks: [],
});

// getters
const getters = {
  get_lessons: (state) => state.lessons,
  getLessonNames: (state) => {
    if (state.lessons.length) {
      // console.log(state.lessons.map((lesson) => lesson.name));
      // console.log(
      //   state.lessons
      //     .map((lesson) => lesson.name)
      //     .filter((value, index, array) => array.indexOf(value) === index)
      // );
      return state.lessons
        .map((lesson) => lesson.name)
        .filter((value, index, array) => array.indexOf(value) === index);
    } else return [];
  },
  get_users: (state) => state.users,
  get_teachers: (state) => state.teachers,
  get_consultants: (state) => state.consultants,
  get_specifications: (state) => state.specifications,
  get_tasks: (state) => state.tasks,
  user: (state) => state.user,
};

// mutations
const mutations = {
  updateLessons(state, payload) {
    state.lessons = payload;
  },
  updateUsers(state, payload) {
    state.users = payload;
  },
  updateTeachers(state, payload) {
    state.teachers = payload;
  },
  updateSpecifications(state, payload) {
    state.specifications = payload;
  },
  updateTasks(state, payload) {
    state.tasks = payload;
  },
  updateUser(state, payload) {
    state.user = payload;
  },
  updateConsultant(state, payload) {
    state.consultants = payload;
  },
};

// actions
const actions = {
  setLessons({ commit }, payload) {
    commit("updateLessons", payload);
  },
  setUsers({ commit }, payload) {
    commit("updateUsers", payload);
  },
  setTeachers({ commit }, payload) {
    commit("updateTeachers", payload);
  },
  setSpecifications({ commit }, payload) {
    commit("updateSpecifications", payload);
  },
  setTasks({ commit }, payload) {
    commit("updateTasks", payload);
  },
  setUser({ commit }, payload) {
    commit("updateUser", payload);
  },
  setAcademicConsultant({ commit }, payload) {
    commit("updateConsultant", payload);
  },
};

export default {
  namespaced: true,
  name: "admin",
  state,
  getters,
  actions,
  mutations,
};
