import axios from "axios";
import handler from "./Handler";
import auth from "./Auth";
import lang from "./Lang";
import store from "@/store/index";
import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";

const api = axios.create();

// add token to header
api.interceptors.request.use(
  async (request) => {
    await store.dispatch("loading/start");
    const nonce =
      Date.now().toString() + (Math.random() * 1000000000).toFixed();
    request.headers["ApplicationId"] = process.env.VUE_APP_APPLICATION_ID;
    request.headers["Nonce"] = nonce;
    request.headers["Signature"] = Base64.stringify(
      hmacSHA256(nonce, process.env.VUE_APP_PRE_SHARED_KEY)
    );
    if (lang.locale()) request.headers["Accept-Language"] = lang.locale();

    //Auth token if token exist,set it on header request
    let token = auth.getToken();
    if (token) request.headers["Authorization"] = `${token}`;
    return request;
  },
  async (error) => {
    await store.dispatch("loading/finish");
    return Promise.reject(error);
  }
);

// response and error handler
api.interceptors.response.use(
  async (response) => {
    await store.dispatch("loading/finish");
    return handler.response(response);
  },
  async (error) => {
    await store.dispatch("loading/finish");
    return handler.error(error);
  }
);

export default api;
