module.exports = {
  key: "admin",
  admin: {
    components: {
      charts: {
        avg_students_exam_score: "所有学生考试成绩的平均值",
        no_data: "没有数据",
        teaching_progress_report: "教学进度报告",
        review_progress_report: "复习进度报告",
        completed_homeworks_report: "完成的家庭作业报告",
        homeworks_report: "作业报告",
        total_teaching_report: "总教学报告",
        classroomReports: "课堂报告",
        completed_homeworks: "完成作业",
        pending_homeworks_report: "待处理的家庭作业报告",
        pending_homeworks: "等待作业",
        teacher_classes_presentation: "教师课堂报告",
        teacher_survey_reports: "教师调查平均转发数",
      },
      admin_actions: {
        search: "搜索",
        add_user: "添加用户",
        add_lesson: "添加课程",
        add_specification: "添加课程",
        add_task: "添加任务",
        add_class: "添加课堂",
      },
      main_table: {
        details: "细节",
      },
      user_table: {
        add_user: "添加用户",
        edit_user: "编辑用户",
        users: "用户",
        lessons: "课程",
        header: {
          name: "姓名",
          nickname: "英文名",
          username: "用户名",
          role: "身份",
          consultant: "顾问",
        },
      },
      lessons_table: {
        add_lesson: "添加课程",
        edit_lesson: "编辑课",
        title: "任务",
        header: {
          name: "科目",
          title: "单元",
          subTitle: "标题",
          level: "等级",
          teachingTimeInHour: "教学时间",
          description: "描述",
        },
      },
      class_table: {
        title: "Classes",
        create: "创建课堂",
      },
      specification_table: {
        add_specification: "添加课程",
        edit_specification: "编辑课程",
        title: "Courses",
        header: {
          title: "标题",
          level: "等级",
          lessons: "课程",
        },
      },
      tasks_table: {
        add_task: "添加任务",
        edit_task: "编辑任务",
        title: "任务",
        header: {
          student_name: "学生的名字",
          create_time: "创建时间",
          total_progress: "总进度",
          teachers: "老师",
          course: "课程",
          lessons: "课程",
        },
      },
      drawer: {
        users: "用户",
        lessons: "课程",
        dashboard: "主页",
        courses: "课程",
        tasks: "任务",
        reports: "报告",
        schedule: "可排课时间",
        notifications: "通知",
        logout: "注销",
        classRoom: "教室",
        finance: "财务",
        contract: "合同",
        upcoming: "预排课表",
      },
    },
    route_title: {
      dashboard: "主页",
      users: "用户",
      lessons: "课程",
      user_reports: "用户报告",
      progressStage: "进展阶段",
      schedule: "课表",
      courses: "课程",
      tasks: "任务",
      class_room_create: "创建教室",
      class_details: "类细节",
      class_room: "教室",
      notifications: "通知",
      contract: "合同",
      reports: "报告",
    },
    ps: {
      academicPlanningPlan: "学业规划方案",
      curriculumPlanning: "课程规划方案",
      subjects: "学习科目",
      teachersName: "授课教师",
      onlineOrOffline: "课程形式",
      booksForStudy: "教材课件",
      topic: "知识点",
      hours: "课时",
      actualHours: "实际时间",
      remark: "备注",
      subtotal: "课时小计 (h)",
      planning: "规划",
      subject: "科目",
      teachingFocus: "教学重点",
      plan: "计划",
      evaluation: "测评",
      studyType: "课程形式",
      online: "线上",
      offline: "线下",
    },
  },
  attribute: {
    //role
    level: "等级",
    role: "身份",
    Admin: "管理",
    Teacher: "老师",
    Student: "学生",
    FinancialAdmin: "金融管理",
    SchedulingAdmin: "调度管理",
    TeamLeader: "学科主管",
    AcademicConsultant: "学术顾问",
    AssistantTeacher: "学业督导员",
    submit: "提交",
    cancel: "取消",
    delete: "删除",
    add_period: "添加时间",
    save: "保存",
    close: "关闭",
    have_class: "上课",
    waiting_for_class: "等待上课",
    add_schedule: "添加日程安排",
    date: "日期",
    start_time: "开始时间",
    start_date: "开始日期",
    start_dates: "开始日期",
    see_more: "查看更多",
    add: "添加",
    add_present_info: "展示信息",
    create_period: "创建时期",
    edit_period: "编辑时间",
    end_time: "结束时间",
    canceled: "取消",
    full: "满了",
    fill_and_create_your_period: "请填写输入以添加可用周期",
    delete_sure: "你确定要继续吗?",
    action_is_permanent: "这个动作是永久性的，不能撤销!",
    fill_inputs_create_user: "请填写输入并创建您的用户",
    create_user: "创建用户+",
    lesson_name: "课程名称",
    hour: "小时",
    hours: "小时",
    actions: "行动",
    lesson: "课程",
    lesson_title: "课程标题",
    lesson_subtitle: "副标题",
    avg_all_students_exam_score: "所有学生考试成绩的平均值",
    student_avg_exam_score: "学生平均考试成绩",
    student_exam_score: "学生考试成绩",
    progress_percentage: "进度百分比",
    review_progress_percentage: "评审进度百分比",
    lessons_review_progress: "每个课程的标题回顾进度",
    lessons_teaching_progress: "每个课程标题的教学进度",
    lessons_name_review_progress: "每节课复习进度",
    lessons_name_teaching_progress: "每节课教学进度",
    teaching_progress_percentage: "教学进度百分比",
    specified_time_teach_lesson: "指定的上课时间",
    teaching_time_left: "剩余教学时间",
    teaching_delay: "教学延迟",
    teaching_left: "教学剩余时长",
    total_teach_time: "总教学时间",
    homework_subtitle: "作业副标题",
    passed_time_percent: "所用时间的百分比",
    create_moment: "创建的时刻",
    deadline: "最后期限",
    remaining: "剩下的",
    subtitle: "副标题",
    teaching_time: "小时",
    spec_title: "标题",
    lesson_ids: "课程编号",
    students_name: "学生名字",
    students: "学生",
    teachers_name: "老师名字",
    specification_name: "课程名称",
    specification: "课程名称",
    state: "状态",
    deadLine: "最后期限",
    feedback: "反馈",
    correctPercentation: "正确的比例",
    doneMoment: "完成日期",
    reviewState: "审核状态",
    reviewMoment: "审核日期",
    teachState: "教学状态",
    teachedMoment: "教学日期",
    teachedTimeInHour: "以小时为单位的教学时间",
    teacher: "老师",
    delay: "延迟",
    delayInTeachingTimeInHour: "延迟教学时间",
    teachingTimeLeftInHour: "剩余教学时间",
    examState: "状态",
    score: "分数",
    destination: "目的地",
    exam_feedback: "考试的反馈",
    exam_type: "考试类型",
    edit: "编辑",
    new: "新",
    spec_download_link: "下载网址",
    downloadLink: "下载网址",
    A_LEVEL: "Alevel",
    KS_3: "KS-3 (IG-foundation)",
    GCSE: "GCSE",
    All: "所有",
    all: "所有",
    English_Elementary: "英语基础",
    AdditionalExamination: "附加检查",
    Contest: "比赛",
    IELTS: "IELTS",
    Other: "其他",
    full_name: "全名",
    user_name: "用户名",
    phoneNumber: "电话号码",
    emailAddress: "电子邮件地址",
    empty_task: "没有任务",
    empty_data: "没有数据",
    see_description: "描述",
    fill_and_create_your_task: "请填写输入并创建您的任务",
    others: "其他人",
    create_task: "创建任务+",
    edit_exam: "编辑考试",
    edit_homework: "编辑作业",
    edit_information: "编辑信息",
    created_at: "在创建",
    updated_at: "更新在",
    time_details: "时间细节",
    homework_feedback: "家庭作业的反馈",
    admin: "管理",
    homework: "家庭作业",
    info: "信息",
    exams: "考试",
    status: "状态",
    see_student_tasks: "查看学生任务",
    good_morning: "早上好",
    good_evening: "晚上好",
    good_afternoon: "下午好",
    good_night: "晚上好",
    logout_sure: "您确定要注销吗?",
    add_lesson_to_course: "向课程中添加课程",
    edit_course: "编辑课程",
    add_course: "创建课程+",
    repeated_for: "重复的",
    days: "天",
    period_is_empty: "没有新的时间。请选择一些",
    mathematics: "数学",
    physics: "物理",
    chemistry: "化学",
    biology: "生物",
    science: "科学",
    geography: "地理",
    computerScience: "计算机科学",
    computerScienceICT: "计算机科学",
    financeAndAccounting: "财务与会计",
    history: "历史",
    psychology: "心理",
    foreignLanguage: "外语",
    economics: "经济",
    baseType: "课程分类",
    any: "任何",
    classroomName: "课程名称",
    classroomLevel: "课程等级",
    times: "次",
    //classroom type
    type: "类型",
    Private: "私人",
    room: "教室编号",
    classroomType: "课程类型",
    seeStudents: "看到学生",
    TwoOrThreePerson: "两三个人",
    ThreeOrFourPerson: "三到四个人",
    MoreThanFourPerson: "四人以上",
    Full: "完整的",
    addClass: "添加课堂",
    classroomNumber: "教室编号",
    financialState: "财务状况",
    start: "开始",
    end: "结束",
    homeworks: "作业",
    presentationProgress: "进展报告",
    studentTaskProgress: "任务的进展",
    student_exams_homeworks: "考试和作业",
    exams_homeworks: "考试和作业",
    student_homeworks: "作业",
    createdMoment: "在创建",
    updateMoment: "更新在",
    edit_teaching_state: "编辑教学状态",
    edit_review_state: "编辑评审状态",
    WithOutReview: "没有评论",
    not_available: "没空时间",
    delete_student_from_class: "从班级中删除学生",
    add_student_present_info: "添加学生报告信息",
    edit_presentation: "编辑报告",
    edit_teaching_day_info: "教学日信息",
    feedBack: "反馈",
    isPresent: "现状",
    lessons_report: "课程报告",
    teach_minutes: "教几分钟",
    finance: "金融",
    academic_adviser: "学术顾问",
    side_cost: "附带成本",
    academic_adviser_cost: "类成本",
    add_side_cost: "添加附带成本",
    teaching_salary: "教学工资",
    studentCostPerHour: "每小时成本",
    studentDiscountPerHour: "每小时折扣",
    teacherSalaryPerHour: "小时工资",
    cancel_time: "取消时间",
    WithOutTeach: "怀特怀特教授",
    AddedByFinancialManager: "财务经理添加",
    upcoming_classes: "即将到来的课程",
    filter_by_teacher: "按教师筛选",
    delete_time: "删除时间",
    specifications: "课程",
    classroom: "教室",
    table: "课程列表",
    upcoming: "即将到来的",
    download_links: "下载链接",
    download: "下载",
    link: "链接",
    export_lessons: "导出学科",
    export_exams: "出口的考试",
    classWithNoInformationOrPresentation: "没有信息和演示的课堂",
    lessonName: "课程名称",
    lessonTitle: "课标题",
    otherContent: "其他内容",
    otherContentInfo: "此内容供复习或练习。",
    classMinutes: "上课时间",
    minutes: "分钟",
    timeSummation: "所选时间之和",
    classWithNoPresentation: "没有演示信息的类",
    addHomework: "添加的作业",
    homeworkSubject: "主题",
    homeworkDeadLine: "的最后期限",
    editHomework: "编辑作业",
    confirmClass: "确认类",
    confirmTime: "确认时间",
    confirmClassSure: "你确定这次要确认吗?",
    confirm: "确认",
    confirmedClasses: "确认类",
    students_not_available: "学生不在",
    haveclass: "上课",
    waitingforclass: "等待上课",
    notifications: "通知",
    unReadNotifications: "未读通知",
    notifications_empty: "没什么好担心的",
    makeAllAsRead: "读取所有内容",
    makeAsRead: "使之读",
    createNotification: "创建通知",
    messageType: "类型",
    message: "消息",
    Classroom: "教室",
    TimeTable: "时间表",
    Financial: "金融",
    isRead: "读取",
    unread: "未读的",
    read: "读",
    editStudentInfo: "编辑学生信息",
    teachingType: "教学类型",
    offline: "离线",
    online: "在线",
    editClass: "编辑类",
    noConfirmation: "没有确认",
    confirmedByTeacher: "经老师确认",
    confirmedByAdmin: "经管理员确认",
    revertToTeacher: "回复老师",
    revert: "回复",
    confirmRevertClassSure: "这次你确定要还原吗?",
    exportTodayClasses: "导出今天的课程",
    export: "导出",
    addSpecification: "添加课程",
    users: "用户",
    sessionReport: "会议报告",
    WaitingForAddCourse: "等待添加航线",
    Finished: "已完成",
    Deleted: "删除",
    sessionNumber: "会话",
    //extra
    today: "今天",
    globalReports: "全球报告",
    downloadPDF: "下载PDF",
    day: "天",
    week: "星期",
    month: "月",
    lessons: "章节",
    filterLessons: "按课程过滤",
    selectedTeacher: "选定的老师",
    filterTeachers: "筛选教师",
    searchTeacher: "寻找教师",
    details: "细节",
    search: "搜索",
    Pending: "待办的",
    Done: "完毕",
    WithOutExam: "没有考试",
    WithOutHomeWork: "没有作业",
    present: "展示",
    absent: "缺席的",
    teachingTimeInHour: "教学时间",
    userReport: "用户报告",
    from: "从",
    to: "到",
    sessionFeedBack: "老师评语",
    sessionTeachedLessonsDescription: "教学内容",
    total_teaching_report: "总教学报告",
    level_total_teaching_report: "级别总教学",
    year: "年",
    lesson_total_teaching_report: "课时总教学报告",
    selectMonth: "请从上面的图表中选择月份",
    selectLevel: "请从上面的图表中选择级别",
    University: "大学",
    // totalTeachingHour: "总教学时数", todo
    // totalTeachingHourEachLevel: "每个级别的总教学时数",
    // totalTeachingHourEachLesson: "每课总教学时数",
    //
    // averageOfAllStudentsExamScore: "所有学生考试成绩的平均值",
    //
    // lessonTeachingProgress: "每课教学进度",
    // lessonTitleTeachingProgress: "各课标题 教学进度",
    // lessonReviewProgress: "每节课回顾进度",
    // lessonTitleReviewProgress: "每个课程标题回顾进度",
    partTime: "兼职",
    PartTime: "兼职",
    fullTime: "全职",
    FullTime: "全职",
    teacherType: "教师类型",
    exportGeneralFinancial: "一般财务报告",
    contract: "合同",
    contrcatType: "合同类型",
    studentName: "学生姓名",
    additionalContracts: "附加合同",
    contractValue: "合约价值",
    additionalContractsValue: "额外合同价值",
    ApplicationForIndependentSchools: "私立学校申请",
    ApplicationForUniversities: "申请大学",
    ProjectsOrAcademicConferences: "项目或学术会议",
    addContract: "添加合约",
    editContract: "编辑合同",
    total: "全部的",
    addAdditionalContract: "添加附加合同",
    additionalContractTitle: "标题",
    additionalContractValue: "价值",
    checkFields: "请检查字段",
    seeAdditionalContracts: "看",
    balance: "平衡",
    contractBalance: "合约余额",
    hasNotPaid: "尚未付款",
    contracts: "合约",
    paid: "有薪酬的",
    removeTimes: "删除次数",
    filter_by_students: "按学生筛选",
    addSessions: "添加会话",
    sessions: "会议",
    disable: "禁用",
    active: "积极的",
    homeworkFiles: "作业档案",
    files: "文件",
    uploadBooks: "上传书籍",
    uploadMaterial: "上传材料",
    uploadedBooks: "上传的书籍",
    uploadedMaterials: "上传材料",
    booksEmpty: "没有书",
    materialEmpty: "没有材料",
    zipHint: "仅接受 .zip 或 .rar 文件。",
    setSurvey: "设定调查",
    survey: "民意调查",
    cancelTime: "取消时间",
    veryWeak: "非常弱",
    weak: "虚弱的",
    medium: "中等的",
    good: "好的",
    veryGood: "非常好",
    resetSessionInfo: "重置会话信息",
    progressStage: "进展阶段",
    coursePlan: "课程计划",
    application: "应用",
    keyPlan: "重点计划",
    moreInformation: "更多信息",
    topic: "话题",
    addApplication: "添加应用程序",
    information: "信息",
    editApplication: "编辑申请",
    applications: "应用领域",
    addCoursePlan: "添加课程计划",
    lessonType: "课",
    sessionPerWeek: "每周会话",
    lessonTopic: "课程主题",
    suggestedHours: "建议时间",
    actualHours: "实际时间",
    classType: "班级类型",
    coursePlanStatus: "地位",
    Review: "审查",
    New: "新的",
    editCoursePlan: "编辑课程计划",
    emptyData: "没有数据",
    TeachingFeeWasAdded: "追加教学费",
    ClassFeeWasAdded: "已添加课程费用",
    basicInfo: "基本信息",
    birthday: "出生日期",
    school: "学校",
    grade: "年级",
    abroadStudyDate: "出国留学时间",
    abroadGrade: "出国留学时的成绩",
    passportID: "护照ID",
    passportExpireDate: "护照有效期",
    BankCardTransfer: "银行卡转账",
    POS: "刷卡",
    Cash: "现金",
    Alipay: "支付宝",
    Wechat: "微信",
    paymentMethod: "付款方式",
    balanceInHour: "小时余额",
    haveClassFee: "有班费",
    targetOfStudy: "学习目标",
    targetUniversities: "目标大学",
    targetMajors: "目标专业",
    targetHighSchools: "目标中学",
    targetSubjects: "目标学科",
    subjectResults: "学科成绩",
    phaseOneGoals: "阶段1目标",
    achievementTimeOne: "达成时间",
    phaseTwoGoals: "阶段2目标",
    achievementTimeTwo: "达成时间",
    languageScore: "语言成绩",
    reading: "Reading",
    writing: "Writing",
    speaking: "Speaking",
    listening: "Listening",
    languageTest: "Language test",
    updatedBy: "更新者",
    at: "在",
    normalClass: "正式课表",
    reservedClass: "预排课表（未确认）",
    sessionDate: "会议日期",
    lastLoginDate: "上次登录日期",
    loginHistory: "登录记录",
    fixReservation: "修复预订",
    fixReservations: "修复预订",
    fixReservedSessions: "修复保留会话",
    add_homework: "添加作业",
    restore: "恢复",
    Archive: "档案",
    Archived: "已归档",
  },
  placeholder: {},
};
