export const apexOptions = {
  chart: {
    foreColor: "#969892",
    toolbar: {
      show: false,
      // tools: {
      //   customIcons: [
      //     {
      //       icon: "<i>mdi-account</i>",
      //       // index: 4,
      //       title: "tooltip of the icon",
      //       class: "custom-icon",
      //       click: function (chart, options, e) {
      //         console.log(chart, options, e);
      //       },
      //     },
      //   ],
      // },
    },
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return val > 0 ? val + " %" : val;
    },
  },
  tooltip: {
    theme: "dark",
    enabled: true,
    y: {
      formatter: function (value) {
        return value > 0 ? value + " %" : value;
      },
      title: {
        formatter: function (seriesName) {
          return seriesName + ": ";
        },
      },
    },
  },
  grid: {
    borderColor: "#535A6C",
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
  stroke: {
    // show: true,
    width: 2,
    // colors: ["transparent"],
  },
  yaxis: {
    min: 0,
    max: 100,
  },
  fill: {
    opacity: 1,
  },
};
