import api from "@/tools/Api";
import urls from "@/tools/Urls";
import message from "@/tools/Message";
import { _t } from "@/tools/Utils";

export default {
  async getAllTeachersTeachingFee({
    page = 1,
    limit = 10,
    search = "",
    all = false,
  }) {
    try {
      let schema = {
        page: {
          index: page,
          size: limit,
        },
        where: [],
        order: [
          {
            column: "createMoment",
            descending: true,
          },
        ],
      };
      if (search)
        schema.where.push([
          {
            column: "fullName",
            comparison: "Contains",
            operand: search,
          },
          {
            column: "fullName",
            comparison: "Contains",
            operand: search.charAt(0).toUpperCase() + search.slice(1),
          },
          {
            column: "fullName",
            comparison: "Contains",
            operand: search,
          },
          {
            column: "fullName",
            comparison: "Contains",
            operand: search.charAt(0).toUpperCase() + search.slice(1),
          },
        ]);
      if (all) {
        schema.page.index = 1;
        schema.page.size = -1;
      }
      const { data } = await api.post(
        urls.api("getAllTeachersTeachingFee"),
        schema
      );
      return {
        data: data.data,
        pageCount: Math.ceil(data.totalCount / limit),
      };
    } catch (e) {
      console.log(e);
    }
  },
  async getATeacherTeachingFee({ publicKey }) {
    try {
      let schema = {
        where: [
          [
            {
              column: "publicKey",
              comparison: "Equal",
              operand: publicKey,
            },
          ],
        ],
        order: [
          {
            column: "createMoment",
            descending: true,
          },
        ],
      };

      const { data } = await api.post(
        urls.api("getATeacherTeachingFee"),
        schema
      );
      return data.data[0];
    } catch (e) {
      console.log(e);
    }
  },
  async upsertTeachingFee(payload) {
    try {
      const { isSuccess } = await api.post(
        urls.api("upsertTeachingFee"),
        payload
      );
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async deleteTeacherFee({ teacherFeeId }) {
    try {
      const { isSuccess } = await api.delete(urls.api("deleteTeacherFee"), {
        data: { teacherFeeId },
      });
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
};
