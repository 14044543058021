// initial state

const state = () => ({
  deposits: [],
  deposit: {},
  balances: [],
});

// getters
const getters = {
  deposits: (state) => state.deposits,
  deposit: (state) => state.deposit,
  balances: (state) => state.balances,
};

// mutations
const mutations = {
  setDeposits(state, payload) {
    state.deposits = payload;
  },
  setDeposit(state, payload) {
    state.deposit = payload;
  },
  setBalances(state, payload) {
    state.balances = payload;
  },
};

// actions
const actions = {
  setDeposits({ commit }, payload) {
    commit("setDeposits", payload);
  },
  setBalances({ commit }, payload) {
    commit("setBalances", payload);
  },
};

export default {
  namespaced: true,
  name: "deposit",
  state,
  getters,
  actions,
  mutations,
};
