import colors from "vuetify/lib/util/colors";

export default {
  black: "#333652", // black
  white: colors.white, // white
  primary: "#4340DA",
  accent: colors.blue.lighten5, // deep purple
  secondary: "#808080", // teal
  info: colors.teal.lighten1, // teal lighten-1
  warning: "#FAD02C", // amber
  error: "#F31F1f", // deepOrange accent-4
  decrease: "#4e1e1e", //
  increase: "#162517", //
  success: colors.green.darken2, // green darken-2
  light: "#E9EAEC", // grey lighten-3
  background: colors.blueGrey,
  inputColor: "#fff",
};
