import api from "@/tools/Api";
import urls from "@/tools/Urls";
import store from "@/store";
import message from "@/tools/Message";
import { _t } from "@/tools/Utils";

export default {
  async getNotifications({
    role,
    page = 1,
    limit = 10,
    search = "",
    all = false,
    isRead = null,
  }) {
    try {
      let schema = {
        page: {
          index: page,
          size: limit,
        },
        where: [],
        order: [
          {
            column: "createMoment",
            descending: true,
          },
        ],
      };
      if (search) {
        schema.where.push([
          {
            column: "message",
            comparison: "Contains",
            operand: search,
          },
        ]);
      }
      if (isRead !== null) {
        schema.where.push([
          {
            column: "isRead",
            comparison: "Equal",
            operand: isRead,
          },
        ]);
      }
      if (all) {
        schema.page.index = 1;
        schema.page.size = -1;
      }

      const { data } = await api.post(
        urls.api(
          role === "Admin" ? "admin_notifications" : "teacher_notifications"
        ),
        schema
      );
      await store.dispatch("notification/setNotifications", data.data);
      return {
        pageCount: Math.ceil(data.totalCount / limit),
      };
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async createNotification(payload) {
    try {
      const { isSuccess } = await api.post(
        urls.api("createNotification"),
        payload
      );
      if (isSuccess) message.success(_t("CREATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async readNotification({ ids, publicKey }) {
    try {
      const { isSuccess } = await api.put(urls.api("read_message"), {
        messageIds: ids,
        publicKey,
      });
      if (isSuccess) message.success("Edited successfully");
      return isSuccess;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async readAllNotification({ ids, publicKey }) {
    try {
      const { isSuccess } = await api.put(urls.api("read_message"), {
        messageIds: ids,
        publicKey,
      });
      if (isSuccess) message.success("Edited successfully");
      return isSuccess;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
};
