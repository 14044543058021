import message from "@/tools/Message";
import api from "@/tools/Api";
import urls from "@/tools/Urls";
import moment from "moment";
export default {
  async getPendingHomeworks(key, lessonName = null, classroomId = null) {
    try {
      const { data } = await api.get(
        urls.qs("pending_homeworks", {
          studentPublicKey: key,
          lessonName,
          classroomId,
        })
      );
      return {
        data,
        xaxis: [...data.HomeworksSubTitle],
        series: {
          name: "Pending homeworks",
          data: data.PercentageOfPassedTime.map((i) => Math.floor(i)),
        },
      };
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getCompletedHomeworksReport(
    key = null,
    from = null,
    to = null,
    lessonName = null,
    classroomId = null
  ) {
    try {
      const { data } = await api.get(
        urls.qs("completed_homeworks", {
          studentPublicKey: key,
          from,
          to,
          lessonName,
          classroomId,
        })
      );
      return {
        data,
        xaxis: [...data.HomeworksSubTitle],
        series: {
          name: "Completed homeworks",
          data: data.HomeworksCorrectPercentation.map((i) => Math.floor(i)),
        },
      };
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getTeachingProgress(
    key = null,
    type = "name",
    lessonName = null,
    classroomId = null
  ) {
    //name || title
    // name or title
    try {
      let data = null;
      if (type === "name") {
        const { data: progress } = await api.get(
          urls.qs(`lesson_${type}_teaching_progress`, {
            publicKey: key,
            lessonName,
            classroomId,
          })
        );
        data = progress;
      } else {
        const { data: progress } = await api.get(
          urls.qs(`lesson_${type}_teaching_progress`, {
            publicKey: key,
            lessonName,
            classroomId,
          })
        );
        data = progress;
      }
      return { data };
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getReviewProgress(
    key = null,
    type = "name",
    lessonName = null,
    classroomId = null
  ) {
    //name || title
    // name or title
    try {
      let data = null;
      if (type === "name") {
        const { data: progress } = await api.get(
          urls.qs(`lesson_${type}_review_progress`, {
            publicKey: key,
            lessonName,
            classroomId,
          })
        );
        data = progress;
      } else {
        const { data: progress } = await api.get(
          urls.qs(`lesson_${type}_review_progress`, {
            publicKey: key,
            lessonName,
            classroomId,
          })
        );
        data = progress;
      }
      return { data };
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async studentExamsScore({
    type = "Total",
    key = null,
    from = null,
    to = null,
    classroomId = null,
  }) {
    //Total, NameExam, TitleExam, SubTitleExam
    try {
      const data = await api.get(
        urls.qs("student_exams_score", {
          studentPublicKey: key,
          examType: type,
          from,
          to,
          classroomId,
        })
      );
      return data;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async studentAvgExamsScore({ type = "Total", key, classroomId }) {
    //Total, NameExam, TitleExam, SubTitleExam
    try {
      const { data } = await api.get(
        urls.qs("student_avg_exams_score", {
          studentPublicKey: key,
          examType: type,
          classroomId,
        })
      );
      return data;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  //GetAvgOfAllStudentsExamsScoreReport
  async GetAllStudentsAvgExamScore(
    type = "Total",
    from = null,
    to = null,
    lessonName = null
  ) {
    try {
      from ? (from = new Date(from).toISOString()) : null;
      to ? (to = new Date(to).toISOString()) : null;
      const { data } = await api.get(
        urls.qs("students_average_exams_score", {
          examType: type,
          from,
          to,
          examsNames: lessonName && type === "SubTitleExam" ? [lessonName] : [],
        })
      );
      return data;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getTasksByPublicKey(id) {
    try {
      let schema = {
        where: [
          [
            {
              column: "publicKey",
              comparison: "Equal",
              operand: id,
            },
          ],
        ],
        order: [
          {
            column: "taskCreateMoment",
            descending: true,
          },
        ],
      };
      const { data } = await api.post(
        urls.api("get_tasks_by_public_key"),
        schema
      );
      return {
        tasks: data.data,
      };
    } catch (e) {
      message.error(e.response.data.Message);
    }
  },
  async getCompletedHomeworksTimelineReport({ key = null }) {
    try {
      const { data } = await api.get(
        urls.qs("completed_homework_timeline", {
          publicKey: key,
        })
      );
      let series = [];
      for (const detail of data.HomeworksDetails) {
        series.push({
          x: detail.form,
          y: [
            moment(detail.homeworkCreateMoment).format("YYYY-MM-DD"),
            moment(detail.homeworkworkDoneMoment).format("YYYY-MM-DD"),
          ],
        });
      }
      return { data, series };
    } catch (e) {
      console.log(e);
    }
  },
  async getStudentPresentationReport({ teacherPublicKey }) {
    try {
      const { data } = await api(
        urls.qs("getStudentPresentationReport", { teacherPublicKey })
      );
      console.log(data, "getStudentPresentationReport");
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async getClassroomReportOfStudent({ publicKey }) {
    try {
      const { data } = await api(
        urls.qs("getClassroomReportOfStudent", { publicKey })
      );
      console.log(data, "getClassroomReportOfStudent");
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async getTotalTeachingHourReport({ year }) {
    try {
      const { data } = await api(
        urls.qs("getTotalTeachingHourReport", {
          year,
        })
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async getEachLevelTotalTeachingHour({ year, month }) {
    try {
      const { data } = await api(
        urls.qs("getEachLevelTotalTeachingHour", { year, month })
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async getEachLessonTotalTeachingHour({ year, month, level }) {
    try {
      const { data } = await api(
        urls.qs("getEachLessonTotalTeachingHour", { year, month, level })
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  },
};
