function loadActions() {
  const context = require.context("@/packages", true, /actions.js$/i);
  return context
    .keys()
    .map(context) // import module
    .map((m) => m.default); // get `default` export from each resolved module
}
function loadGeneralsActions() {
  const context = require.context("@/actions", true, /actions.js$/i);
  return context
    .keys()
    .map(context) // import module
    .map((m) => m.default); // get `default` export from each resolved module
}
const resourceModules = {};
loadActions().forEach((resource) => {
  for (let i in resource) {
    resourceModules[i] = resource[i];
  }
});
loadGeneralsActions().forEach((resource) => {
  for (let i in resource) {
    resourceModules[i] = resource[i];
  }
});
export default {
  install(Vue) {
    // an instance method
    Vue.prototype.$actions = { ...resourceModules };
  },
};
