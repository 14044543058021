module.exports = {
  components: {
    app: {
      header: {
        register: "Register",
        login: "Login",
        logout: "Logout",
      },
      snackbar: {
        close: "Close",
      },
      page_not_found: {
        title: "404",
        subtitle_part_one: "Sorry!",
        subtitle_part_two: "Page Not Found",
        buttons: {
          go_back: "Go Back",
        },
      },
      headerr: {
        dashboard: "Dashboard",
        home: "",
        logout: "Logout",
        language: {
          change_language: "Change Language",
          english: "English",
          chinese: "Chinese",
        },
      },
    },
    dashboard: {},
  },
  router: {
    index: {
      panel_title: "Panel",
    },
    routes: {
      panel_routes: {
        dashboard: "Dashboard",
        profile: "Profile",
      },
      page_not_found_routes: {
        not_found: "Page not found",
      },
    },
  },
  views: {},
  layouts: {
    nav_items: {},
  },
  attribute: {
    description: "Description",
    title: "Title",
    examTypes: {
      Total: "Total",
      Pending: "Pending",
      TitleExam: "Chapter Evaluation",
      NameExam: "Review Exam",
      SubTitleExam: "Review Quiz",
      All: "All",
    },
    Done: "Done",
    Pending: "Pending",
    WithOutHomeWork: "Without Homework",
    changePassword: "Change password",
    old_password: "Current password",
    new_password: "New password",
    confrim_password: "Confirm password",
  },
  validations: {
    required: "{attribute} can not be empty.",
    work_mail_required: "Enter your work email.",
    alpha: "{attribute} Can only contain letters A-Z.",
    alpha_num: "{attribute} Can only contain letters A-Z and numbers 9-0",
    alpha_dash: "{attribute} Can contain of letter, numbers, - and _. ",
    alpha_spaces: "{attribute} Can only contain letter A-Z as well as space.",
    between:
      "{attribute} Can contain minimum {min} characters and maximum {max} characters.",
    confirmed: "{attribute} dose not match.",
    digits: "{attribute} Must contain numbers and exactly {length} characters.",
    length:
      "{attribute} Must contain characters and exactly {length} characters.",
    dimensions:
      "{attribute} Dimensions need to be {width} pixels height and {height} pixels width.",
    email: "Must be a valid email address.",
    excluded: "{attribute} Must be a valid input.",
    ext: "{attribute} Must be a valid file format.",
    image: "{attribute} Must be an image.",
    maxValue: "{attribute} Must be {max} or less than {max}",
    max: "{attribute} Cannot be longer than {length}",
    mimes: "{attribute} Must be a valid format.",
    minValue: "{attribute} Must be {min} or more than {min}",
    minLength: "{attribute} Must be {min} or more than {min}",
    min: "{attribute} Must be at least {length} characters.",
    numeric: "{attribute} Can only contain numbers.",
    decimal: "{attribute} must be a valid decimal.",
    oneOf: "{attribute} Must be a valid input.",
    regex:
      "{attribute} must contain at least an Upper case letter,lower case letter,special character(# $ @ %) and a number.",
    required_if: "{attribute} Mandatory.",
    size: "{attribute} File size can be {size}KB",
    double: "{attribute} Must be a valid decimal number.",
    sameAsPassword: "{attribute} must be same as password.",
    phone_code: "{attribute} must start with country code",
    url: "{attribute} must start with http:// or https://",
  },

  FAILED_VALIDATION: "Validation for this particular item failed",
  ACCESS_DENIED: "You are not allowed to do the current action",
  INVALID_GRANT: "Username / password or access token is wrong",
  LOGIN_SUCCESS: "You are logged in successfully.",
  UNAUTHORIZED: "UNAUTHORIZED",
  INVALID_OTP: "Wrong OTP was provided",
  INVALID_PAYLOAD: "Provided payload is invalid",
  INVALID_QUERY: "The requested query parameters can not be used",
  REQUESTS_EXCEEDED: "Hit rate limit; Too many requests",
  ROUTE_NOT_FOUND: "Endpoint does not exist",
  SERVICE_UNAVAILABLE: "Could not use external service",
  FETCH_DATA_FAILED: "Could not fetch data.",
  CREATE_SUCCESS: "Created successfully.",
  CREATE_FAILED: "Did not create.",
  UPDATE_SUCCESS: "Updated successfully.",
  UPDATE_FAILED: "Did not update.",
  DELETE_SUCCESS: "Deleted successfully.",
  DELETE_FAILED: "Did not delete.",
  ADD_SUCCESS: "Added successfully",
  DELETE_MESSAGE: "Are you sure you want to delete this address?",
  NO_RESULT: "There is no result.",
  "Invalid user credentials.": "Invalid user credentials.",
  EVENT_UPDATE_SUCCESSFUL: "EVENT SUCCESSFULLY UPDATED",
  FILE_EMPTY: "please choose your file",
  SELECT_LESSONS: "Please select lessons",
  CHANGE_PASSWORD_HINT: "If you change this field, password will be changed!!",
  FILL_CORRECT: "Please fill the fields",
  EMPTY_TIME: "Please add time",
  EMPTY_ITEM: "There is no item",
  SELECT: (ctx) =>
    `Please select ${ctx.messages[ctx.locale]["attribute"][ctx.named("name")]}`,
};
