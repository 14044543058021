import api from "@/tools/Api";
import urls from "@/tools/Urls";
import message from "@/tools/Message";
import store from "@/store";
import { _t } from "@/tools/Utils";

export default {
  async getUsers({
    page = 1,
    limit = 10,
    search = "",
    role = "",
    all = false,
    dispatch = false,
    baseType = null,
    ignoreRoleFilter = false,
  }) {
    try {
      let schema = {
        page: {
          index: page,
          size: limit,
        },
        where: [],
        order: [
          {
            column: "createMoment",
            descending: true,
          },
        ],
      };
      if (search)
        schema.where.push([
          {
            column: "fullName",
            comparison: "Contains",
            operand: search,
          },
          {
            column: "nickName",
            comparison: "Contains",
            operand: search,
          },
          {
            column: "userName",
            comparison: "Contains",
            operand: search,
          },
          {
            column: "fullName",
            comparison: "Contains",
            operand: search.charAt(0).toUpperCase() + search.slice(1),
          },
          {
            column: "fullName",
            comparison: "Contains",
            operand: search.toUpperCase(),
          },
          {
            column: "nickName",
            comparison: "Contains",
            operand: search.charAt(0).toUpperCase() + search.slice(1),
          },
          {
            column: "userName",
            comparison: "Contains",
            operand: search.charAt(0).toUpperCase() + search.slice(1),
          },
        ]);
      if (role) {
        if (typeof role === "string")
          schema.where.push([
            {
              column: "role",
              comparison: "Equal",
              operand: role,
            },
          ]);
        if (typeof role === "object") {
          schema.where.push(
            role.map((r) => {
              return {
                column: "role",
                comparison: "Equal",
                operand: r,
              };
            })
          );
        }
      }
      if (baseType) {
        schema.where.push([
          {
            column: "baseType",
            comparison: "Equal",
            operand: baseType,
          },
        ]);
      }
      if (all) {
        schema.page.index = 1;
        schema.page.size = -1;
      }
      const { data } = await api.post(
        urls.qs("admin_users", { ignoreRoleFilter }),
        schema
      );
      if (dispatch) {
        await store.dispatch(`${dispatch}`, data.data);
      }
      return {
        users: data.data,
        pageCount: Math.ceil(data.totalCount / limit),
        total: data.totalCount,
      };
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getAllUsers({ roles = [], dispatches = [], ignoreRoleFilter }) {
    try {
      let schema = {
        where: [],
        order: [
          {
            column: "createMoment",
            descending: true,
          },
        ],
      };
      if (roles) {
        if (typeof role === "object") {
          schema.where.push(
            roles.map((r) => {
              return {
                column: "role",
                comparison: "Equal",
                operand: r,
              };
            })
          );
        }
      }
      const { data } = await api.post(
        urls.qs("admin_users", { ignoreRoleFilter }),
        schema
      );
      for (const rolesKey in roles) {
        await store.dispatch(
          `${dispatches[rolesKey]}`,
          data.data.filter((u) => u.role === roles[rolesKey])
        );
      }
    } catch (e) {
      console.log(e);
    }
  },
  async getAcademicConsultants() {
    try {
      const { data } = await api.post(urls.api("getAcademicConsultants"));
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async getUsersByRoleAndBaseType({
    search = "",
    role = [],
    baseType = [],
    dispatch = false,
  }) {
    try {
      const { data } = await api.post(urls.qs("user_by_base_type"), {
        index: 1,
        size: -1,
        roles: role,
        baseTypes: baseType,
        fullName: search,
      });
      if (dispatch) {
        await store.dispatch(`${dispatch}`, data.data);
      }
      return { users: data.data };
    } catch (e) {
      console.log(e);
    }
  },
  async getCountOfEachRole() {
    try {
      const { data } = await api(urls.api("GetCountOfEachRole"));
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async getUserByPublicKey(publicKey) {
    try {
      let schema = {
        where: [
          [
            {
              column: "publicKey",
              comparison: "Equal",
              operand: publicKey,
            },
          ],
        ],
      };
      const { data } = await api.post(urls.api("admin_users"), schema);
      if (data.data[0]) await store.dispatch("admin/setUser", data.data[0]);
      return {
        user: data.data[0],
      };
    } catch (e) {
      console.error(e.response.data);
      message.error(e.response.data.Message);
    }
  },
  async getPermissionsPerRole(role) {
    try {
      const { data } = await api.get(
        urls.qs("permissions_per_role", { role: role })
      );

      return {
        keys: Object.keys(data),
        data: Object.keys(data).map((k) => {
          return { text: data[k], value: k };
        }),
      };
    } catch (e) {
      message.error(e.response.data.Message);
      return e.response.data;
    }
  },
  async register(payload) {
    try {
      const res = await api.post(urls.api("auth_register"), payload);
      if (res.data) message.success(_t("CREATE_SUCCESS"));
      return res.data;
    } catch (e) {
      message.error(e.response.data.Message);
      return e.response.data;
    }
  },
  async resetPassword(payload) {
    try {
      const res = await api.put(urls.api("reset_password"), payload);
      if (res.data) message.success("password reset successfully");
      return res.data;
    } catch (e) {
      message.error(e.response.data.Message);
    }
  },
  async editUser(payload) {
    try {
      const res = await api.put(urls.api("edit_user"), payload);
      if (res.data) message.success("User edited successfully");
      return res.data;
    } catch (e) {
      message.error(e.response.data.Message);
    }
  },
  async deleteUser(key) {
    try {
      const res = await api.delete(urls.api("auth_delete"), {
        data: { publicKey: key },
      });
      if (res.data) message.success("User deleted successfully");
      return res.data;
    } catch (e) {
      message.error(e.response.data.Message);
    }
  },
  async getLoginHistory({ publicKey }) {
    try {
      const { data } = await api.get(urls.qs("getLoginHistory", { publicKey }));
      return data[0].loginHistory.reverse();
    } catch (e) {
      console.log(e);
    }
  },
  async archiveUser(payload) {
    try {
      const { isSuccess } = await api.put(urls.api("archiveUser"), payload);
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
};
