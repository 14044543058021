import api from "@/tools/Api";
import urls from "@/tools/Urls";
import message from "@/tools/Message";
import store from "@/store";

export default {
  async getLessons({
    page = 1,
    limit = 10,
    search = "",
    level = "",
    all = false,
    baseType = "",
    dispatch = null,
  }) {
    try {
      let schema = {
        page: {
          index: page,
          size: limit,
        },
        where: [],
        order: [
          {
            column: "createMoment",
            descending: true,
          },
        ],
      };
      if (search)
        schema.where.push([
          {
            column: "name",
            comparison: "Contains",
            operand: search,
          },
          {
            column: "title",
            comparison: "Contains",
            operand: search,
          },
          {
            column: "subTitle",
            comparison: "Contains",
            operand: search,
          },
          {
            column: "name",
            comparison: "Contains",
            operand: search.charAt(0).toUpperCase() + search.slice(1),
          },
          {
            column: "title",
            comparison: "Contains",
            operand: search.charAt(0).toUpperCase() + search.slice(1),
          },
          {
            column: "subTitle",
            comparison: "Contains",
            operand: search.charAt(0).toUpperCase() + search.slice(1),
          },
        ]);
      if (level) {
        schema.where.push([
          {
            column: "level",
            comparison: "Equal",
            operand: level,
          },
        ]);
      }
      if (baseType) {
        schema.where.push([
          {
            column: "baseType",
            comparison: "Equal",
            operand: baseType,
          },
        ]);
      }
      if (all) {
        schema.page.index = 1;
        schema.page.size = -1;
      }
      const { data } = await api.post(urls.api("admin_lessons"), schema);

      if (dispatch) {
        await store.dispatch(`${dispatch}`, data.data);
      }
      return {
        lessons: data.data,
        pageCount: Math.ceil(data.totalCount / limit),
      };
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async createLesson(payload) {
    try {
      const res = await api.post(urls.api("create_lesson"), payload);
      if (res.data) message.success("Lesson created successfully");
      return res.data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async editLesson(payload) {
    try {
      const res = await api.put(urls.api("edit_lesson"), payload);
      if (res.data) message.success("Lesson edited successfully");
      return res.data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async deleteLesson(key) {
    try {
      const res = await api.delete(urls.api("delete_lesson"), {
        data: {
          lessonId: key,
        },
      });
      if (res.data) message.success("Lesson deleted successfully");
    } catch (e) {
      console.log(e.response.data);
      message.error(e.response.data.Message);
    }
  },
  async getTitles() {
    try {
      const { data } = await api.get(urls.api("lessons_title"));
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async getNames() {
    try {
      const { data } = await api.get(urls.api("lessons_names"));
      return data;
    } catch (e) {
      console.log(e);
    }
  },
};
