// initial state

const state = () => ({
  settlements: [],
});

// getters
const getters = {
  settlements: (state) => state.settlements,
};

// mutations
const mutations = {
  setSettlements(state, payload) {
    state.settlements = payload;
  },
};

// actions
const actions = {
  setSettlements({ commit }, payload) {
    commit("setSettlements", payload);
  },
};

export default {
  namespaced: true,
  name: "settlement",
  state,
  getters,
  actions,
  mutations,
};
