module.exports = {
  key: "admin",
  admin: {
    components: {
      charts: {
        avg_students_exam_score: "Average of all students exam scores",
        no_data: "There is no data",
        teaching_progress_report: "Teaching progress report",
        review_progress_report: "Reviewing progress report",
        completed_homeworks_report: "Completed homeworks report",
        homeworks_report: "Homeworks report",
        total_teaching_report: "Total teaching report",
        classroomReports: "Class reports",
        completed_homeworks: "Completed homeworks",
        pending_homeworks_report: "Pending homeworks report",
        pending_homeworks: "Pending homeworks",
        teacher_classes_presentation: "Teacher class presentation",
        teacher_survey_reports: "Teacher survey average repost",
      },
      admin_actions: {
        search: "Search",
        add_user: "Add user",
        add_lesson: "Add lesson",
        add_specification: "Add course",
        add_task: "Add task",
        add_class: "Add class",
      },
      main_table: {
        details: "Details",
      },
      user_table: {
        add_user: "Add user",
        edit_user: "Edit user",
        users: "Users",
        lessons: "Lessons",
        header: {
          name: "Name",
          nickname: "Nickname",
          username: "Username",
          consultant: "Consultant",
          role: "Role",
        },
      },
      lessons_table: {
        add_lesson: "Add lesson",
        edit_lesson: "Edit lesson",
        title: "Lessons",
        header: {
          name: "Name",
          title: "Title",
          subTitle: "Sub title",
          level: "Level",
          teachingTimeInHour: "Teaching time in hour",
          description: "Description",
        },
      },
      class_table: {
        title: "Classes",
        create: "Create class",
      },
      specification_table: {
        add_specification: "Add course",
        edit_specification: "Edit course",
        title: "Courses",
        header: {
          title: "Title",
          level: "Level",
          lessons: "Lessons",
        },
      },
      tasks_table: {
        add_task: "Add task",
        edit_task: "Edit task",
        title: "Tasks",
        header: {
          student_name: "Student name",
          create_time: "Create time",
          total_progress: "Total progress",
          teachers: "Teachers",
          lessons: "Lessons",
          course: "Course",
        },
      },
      drawer: {
        dashboard: "Dashboard",
        users: "Users",
        lessons: "Lessons",
        courses: "Courses",
        tasks: "Tasks",
        reports: "Reports",
        schedule: "Schedule",
        notifications: "Notifications",
        logout: "Logout",
        classRoom: "Class room",
        finance: "Finance",
        contract: "Contract",
        upcoming: "Upcoming",
      },
    },
    route_title: {
      dashboard: "Dashboard",
      users: "Users",
      lessons: "Lessons",
      courses: "Courses",
      tasks: "Tasks",
      user_reports: "User reports",
      progressStage: "Progress stage",
      schedule: "Schedule",
      class_room_create: "Create class room",
      class_details: "Class details",
      class_room: "Class room",
      notifications: "Notifications",
      contract: "Contract",
      reports: "Reports",
    },
    ps: {
      academicPlanningPlan: "Academic planning plan",
      curriculumPlanning: "Curriculum Planning Plan",
      subjects: "Subjects",
      teachersName: "Teacher's name",
      onlineOrOffline: "Online or Offline study",
      booksForStudy: "Books for study",
      topic: "Topic",
      hours: "Hours",
      actualHours: "Actual hours",
      remark: "Remark",
      subtotal: "Lesson subtotal (h)",
      planning: "Planning",
      subject: "Subject",
      teachingFocus: "Teaching focus",
      plan: "Plan",
      evaluation: "Evaluation",
      studyType: "Study type",
      online: "Online",
      offline: "Offline",
    },
  },
  attribute: {
    //role
    Admin: "Admin",
    Teacher: "Teacher",
    Student: "Student",
    FinancialAdmin: "Financial admin",
    SchedulingAdmin: "Scheduling admin",
    TeamLeader: "Team leader",
    AcademicConsultant: "Academic consultant",
    AssistantTeacher: "Teacher assistant",
    submit: "Submit",
    cancel: "Cancel",
    delete: "Delete",
    add_period: "Add period",
    save: "Save",
    close: "Close",
    have_class: "Have class",
    waiting_for_class: "Waiting for class",
    add_schedule: "Add schedule",
    date: "Date",
    start_time: "Start time",
    start_date: "Start date",
    start_dates: "Start dates",
    see_more: "See more",
    add: "Add",
    add_present_info: "Presentation info",
    create_period: "Create period",
    edit_period: "Edit period",
    end_time: "End time",
    canceled: "Canceled",
    full: "Full",
    fill_and_create_your_period: "Please fill inputs to add available period",
    delete_sure: "Are you sure you want to continue?",
    action_is_permanent: "This action is permanent and cannot be undone!",
    fill_inputs_create_user: "Please fill inputs and create your users",
    edit_user: "Edit user",
    create_user: "Create user +",
    lesson_name: "Lesson name",
    name: "name",
    hour: "Hour",
    hours: "Hours",
    actions: "Actions",
    lesson: "Lesson",
    lesson_title: "Lesson title",
    lesson_subtitle: "Subtitle",
    avg_all_students_exam_score: "Average of all students exam scores",
    student_avg_exam_score: "Student average exams score",
    student_exam_score: "Student exams score",
    progress_percentage: "Progress Percentage",
    review_progress_percentage: "Review progress percentage",
    lessons_review_progress: "Each Title of Lessons Reviewing Progress",
    lessons_teaching_progress: "Each Title of Lessons Teaching Progress",
    lessons_name_review_progress: "Each Lesson Reviewing Progress",
    lessons_name_teaching_progress: "Each Lesson Teaching Progress",
    teaching_progress_percentage: "Teaching progress percentage",
    specified_time_teach_lesson: "Specified time to teach lesson",
    teaching_time_left: "Teaching time left",
    teaching_delay: "Teaching delay",
    teaching_left: "Teaching left",
    total_teach_time: "Total taught time",
    homework_subtitle: "Homework subtitle",
    passed_time_percent: "Percentage of passed time",
    create_moment: "Create moment",
    deadline: "DeadLine",
    remaining: "Remaining",
    subtitle: "Subtitle",
    level: "Level",
    teaching_time: "Hours",
    teachingTimeInHour: "Teaching time",
    spec_title: "Title",
    lesson_ids: "Lesson Ids",
    role: "Role",
    students_name: "Student name",
    students: "Students",
    teachers_name: "Teachers name",
    teachers: "Teachers",
    specification_name: "Course name",
    specification: "Course name",
    state: "Status",
    deadLine: "DeadLine",
    feedback: "Feedback",
    correctPercentation: "Correct percentage",
    doneMoment: "Done date",
    reviewState: "Review state",
    reviewMoment: "Review date",
    teachState: "Teach State",
    teachedMoment: "Teach date",
    teachedTimeInHour: "Teach time in hour",
    teacher: "Teacher",
    delay: "Delay",
    delayInTeachingTimeInHour: "Delay in teaching time",
    teachingTimeLeftInHour: "Teaching time left",
    examState: "State",
    score: "Score",
    destination: "Destination",
    exam_feedback: "Exam feedback",
    exam_type: "Exam type",
    edit: "Edit",
    new: "New",
    spec_download_link: "Download URL",
    downloadLink: "Download URL",
    A_LEVEL: "A-LEVEL",
    KS_3: "KS-3 (IG-foundation)",
    GCSE: "GCSE",
    All: "All",
    all: "All",
    English_Elementary: "English elementary",
    AdditionalExamination: "Additional examination",
    Contest: "Contest",
    IELTS: "IELTS",
    TOEFL: "TOEFL",
    Other: "Other",
    full_name: "Full name",
    user_name: "User name",
    nickname: "Nickname",
    phoneNumber: "Phone number",
    emailAddress: "Email address",
    empty_task: "There are no tasks",
    empty_data: "There are no data",
    see_description: "Description",
    description: "Description",
    fill_and_create_your_task: "Please fill inputs and create your task",
    others: "others",
    create_task: "Create task +",
    edit_task: "Edit task",
    edit_exam: "Edit exam",
    edit_homework: "Edit homework",
    edit_information: "Edit information",
    created_at: "Created at",
    updated_at: "Updated at",
    lessons: "Lessons",
    details: "Details",
    time_details: "Time details",
    homework_feedback: "Homework feedback",
    admin: "Admin",
    homework: "Homework",
    info: "Info",
    exams: "Exams",
    status: "State",
    reports: "Reports",
    see_student_tasks: "See student tasks",
    good_morning: "Good morning",
    good_evening: "Good evening",
    good_afternoon: "Good afternoon",
    good_night: "Good night",
    add_task: "Add task",
    logout: "Logout",
    logout_sure: "Are you sure you want to logout?",
    add_lesson_to_course: "Add lessons to course",
    edit_course: "Edit course",
    add_course: "Create course +",
    repeated_for: "Repeated for",
    days: "Days",
    period_is_empty: "There are no new times. please select some",
    mathematics: "Mathematics",
    physics: "Physics",
    chemistry: "Chemistry",
    biology: "Biology",
    geography: "Geography",
    science: "Science",
    computerScience: "computer science ICT",
    financeAndAccounting: "Finance and accounting",
    history: "History",
    psychology: "Psychology",
    foreignLanguage: "Foreign language",
    economics: "Economics",
    baseType: "Lesson type",
    any: "Any",
    classroomName: "Class name",
    classroomLevel: "Class level",
    times: "Times",
    //classroom type
    type: "Type",
    Private: "Private",
    room: "Room",
    classroomType: "Class type",
    seeStudents: "See students",
    TwoOrThreePerson: "Two or three person",
    ThreeOrFourPerson: "Three or four person",
    MoreThanFourPerson: "More than four person",
    Full: "Full",
    addClass: "Add class",
    classroomNumber: "Classroom number",
    financialState: "Financial state",
    start: "Start",
    end: "End",
    homeworks: "Homeworks",
    presentationProgress: "Progress presentation",
    studentTaskProgress: "Task progress",
    student_exams_homeworks: "Exams & Homeworks",
    exams_homeworks: "Exams & Homework",
    student_homeworks: "Homeworks",
    createdMoment: "Created at",
    updateMoment: "Updated at",
    edit_teaching_state: "Edit teaching state",
    edit_review_state: "Edit review state",
    WithOutReview: "Without review",
    not_available: "Not available time",
    delete_student_from_class: "Delete student from class",
    add_student_present_info: "Add student present info",
    edit_presentation: "Edit presentation",
    edit_teaching_day_info: "Teaching day info",
    feedBack: "Feedback",
    isPresent: "Present status",
    lessons_report: "Lessons report",
    teach_minutes: "Teach minutes",
    //finance
    finance: "Finance",
    academic_adviser: "Academic adviser",
    side_cost: "Side cost",
    academic_adviser_cost: "Class cost",
    add_side_cost: "Add side cost",
    teaching_salary: "Teaching salary",
    studentCostPerHour: "Cost per hour",
    studentDiscountPerHour: "Discount per hour",
    teacherSalaryPerHour: "Salary per hour",
    cancel_time: "Cancel time",
    WithOutTeach: "Whit out teach",
    AddedByFinancialManager: "Added by financial manager",
    upcoming_classes: "Upcoming classes",
    filter_by_teacher: "Filter by teacher",
    delete_time: "Remove time",
    specifications: "Courses",
    classroom: "Classroom",
    table: "Table",
    upcoming: "Upcoming",
    download_links: "Download links",
    download: "Download",
    link: "Link",
    export_lessons: "Export lessons",
    export_exams: "Export exams",
    classWithNoInformationOrPresentation:
      "Class with no information and presentation",
    lessonName: "Lesson name",
    lessonTitle: "Lesson title",
    otherContent: "Other content",
    otherContentInfo: "This content is for review or practice.",
    classMinutes: "Time of class",
    minutes: "Minutes",
    timeSummation: "Sum of selected times",
    classWithNoPresentation: "Class with no presentation info",
    addHomework: "Add homework",
    homeworkSubject: "Subject",
    homeworkDeadLine: "DeadLine",
    editHomework: "Edit homework",
    confirmClass: "Confirm class",
    confirmTime: "Confirm time",
    confirmClassSure: "Are you sure want to confirm this time?",
    confirm: "Confirm",
    confirmedClasses: "Confirmed classes",
    students_not_available: "Student not available",
    haveclass: "Have class",
    waitingforclass: "Waiting for class",
    //notifications
    notifications: "Notifications",
    unReadNotifications: "Unread notifications",
    notifications_empty: "There is nothing to concern",
    makeAllAsRead: "Make all as read",
    makeAsRead: "Make as read",
    createNotification: "Create notification",
    messageType: "Type",
    message: "Message",
    Classroom: "Classroom",
    TimeTable: "Timetable",
    Financial: "Financial",
    isRead: "Is read",
    unread: "Unread",
    read: "Read",
    editStudentInfo: "Edit student information",
    teachingType: "Teaching type",
    offline: "Offline",
    online: "Online",
    editClass: "Edit class",
    noConfirmation: "No confirmation",
    confirmedByTeacher: "Confirmed by teacher",
    confirmedByAdmin: "Confirmed by Admin",
    revertToTeacher: "Revert to teacher",
    revert: "Revert",
    confirmRevertClassSure: "Are you sure want to revert this time?",
    exportTodayClasses: "Export today classes",
    export: "Export",
    addSpecification: "Add course",
    users: "Users",
    sessionReport: "Session report",
    WaitingForAddCourse: "Waiting for add course",
    Finished: "Finished",
    Deleted: "Deleted",
    sessionNumber: "Session",
    University: "University",
    //extra
    today: "Today",
    globalReports: "Global reports",
    downloadPDF: "Download PDF",
    day: "Day",
    week: "Week",
    month: "Month",
    filterLessons: "filter by lessons",
    selectedTeacher: "Selected teacher",
    filterTeachers: "Filter teachers",
    searchTeacher: "search in teachers",
    search: "Search",
    Pending: "Pending",
    Done: "Done",
    WithOutExam: "Without exam",
    WithOutHomeWork: "Without homework",
    present: "Present",
    absent: "Absent",
    userReport: "User reports",
    from: "From",
    to: "To",
    sessionFeedBack: "Teacher's comment",
    sessionTeachedLessonsDescription: "Teaching content",
    total_teaching_report: "Total teaching report",
    level_total_teaching_report: "Level total teaching",
    year: "Year",
    computerScienceICT: "Computer science ICT",
    lesson_total_teaching_report: "Lesson total teaching report",
    selectMonth: "Please select month from chart above",
    selectLevel: "Please select level from chart above",
    totalTeachingHour: "Total teaching hour",
    totalTeachingHourEachLevel: "Total teaching hour for each level",
    totalTeachingHourEachLesson: "Total teaching hour for each lesson",

    averageOfAllStudentsExamScore: "Average of all students exam scores",

    lessonTeachingProgress: "Each Lesson Teaching Progress",
    lessonTitleTeachingProgress: "Each Title of Lessons Teaching Progress",
    lessonReviewProgress: "Each Lesson Reviewing Progress",
    lessonTitleReviewProgress: "Each Title of Lessons Reviewing Progress",
    partTime: "Part time",
    PartTime: "Part time",
    fullTime: "Full time",
    FullTime: "Full time",
    teacherType: "Teacher type",
    exportGeneralFinancial: "General financial report",
    contract: "Contract",
    contrcatType: "Contract type",
    studentName: "Student name",
    additionalContracts: "Additional contracts",
    addAdditionalContract: "Add additional contract",
    additionalContractTitle: "Title",
    additionalContractValue: "Value",
    contractValue: "Contract value",
    additionalContractsValue: "Additional contracts value",
    ApplicationForIndependentSchools: "Application for independent schools",
    ApplicationForUniversities: "Application for universities",
    ProjectsOrAcademicConferences: "Projects or academic conferences",
    addContract: "Add contract",
    editContract: "Edit contract",
    total: "Total",
    studentHaveClass: "Student classes",
    teacherHaveClass: "Teacher classes",
    checkFields: "Please check fields",
    seeAdditionalContracts: "See",
    balance: "Balance",
    contractBalance: "Contract balance",
    hasNotPaid: "Has not paid",
    contracts: "Contracts",
    paid: "Paid",
    removeTimes: "Remove times",
    filter_by_students: "Filter by students",
    addSessions: "Add sessions",
    sessions: "Sessions",
    disable: "Disable",
    active: "Active",
    homeworkFiles: "Homework file",
    files: "Files",
    uploadBooks: "Upload books",
    uploadMaterial: "Upload material",
    uploadedBooks: "Uploaded books",
    uploadedMaterials: "Uploaded materials",
    booksEmpty: "There are no books",
    materialEmpty: "There are no materials",
    zipHint: "Only .zip or .rar files accepted.",
    setSurvey: "Set survey",
    survey: "Survey",
    cancelTime: "Cancel time",
    veryWeak: "Very weak",
    weak: "Weak",
    medium: "Medium",
    good: "Good",
    veryGood: "Very good",
    resetSessionInfo: "Reset session info",
    progressStage: "Progress stage",
    coursePlan: "Course plan",
    application: "Application",
    keyPlan: "Key plan",
    moreInformation: "More information",
    topic: "Topic",
    addApplication: "Add application",
    information: "Information",
    editApplication: "Edit application",
    applications: "Applications",
    addCoursePlan: "Add course plan",
    lessonType: "Lesson",
    sessionPerWeek: "Session per week",
    lessonTopic: "Lesson topic",
    suggestedHours: "Suggested hours",
    actualHours: "Actual hours",
    classType: "Class type",
    coursePlanStatus: "Status",
    Review: "Review",
    New: "New",
    editCoursePlan: "Edit course plan",
    emptyData: "There is no data",
    TeachingFeeWasAdded: "Teaching fee was added",
    ClassFeeWasAdded: "Class fee was added",
    basicInfo: "Basic Info",
    birthday: "Birthday",
    school: "School",
    grade: "Grade",
    abroadStudyDate: "Time when study abroad",
    abroadGrade: "Grade when study abroad",
    passportID: "Passport ID",
    passportExpireDate: "Passport expire date",
    BankCardTransfer: "Bank card transfer",
    POS: "POS",
    Cash: "Cash",
    Alipay: "Alipay",
    Wechat: "Wechat",
    paymentMethod: "Payment method",
    balanceInHour: "Balance in hour",
    haveClassFee: "Have class Fee",
    targetOfStudy: "Target of study",
    targetUniversities: "Target universities",
    targetMajors: "Target majors",
    targetHighSchools: "Target high schools",
    targetSubjects: "Target subjects",
    subjectResults: "Subject results",
    phaseOneGoals: "Phase 1 goals",
    achievementTimeOne: "Achievement time",
    phaseTwoGoals: "Phase 2 goals",
    achievementTimeTwo: "Achievement time",
    languageScore: "Language score",
    reading: "Reading",
    writing: "Writing",
    speaking: "Speaking",
    listening: "Listening",
    languageTest: "Language test",
    updatedBy: "Updated by",
    at: "at",
    normalClass: "Formal class",
    reservedClass: "Reserved class (unconfirmed)",
    interview: "Interview",
    mat: "MAT",
    tmua: "TMUA (CTMUA)",
    pat: "PAT",
    engaa: "ENGAA",
    nsaa: "NSAA",
    bmat: "BMAT",
    bmo: "BMO",
    smc: "SMC",
    ukcho: "UK CHO",
    c3l6: "C3L6",
    bpho: "BPHO",
    bbo: "BBO",
    amc12: "AMC12",
    aime: "AIME",
    g5: "G5",
    IB: "IB",
    AP: "AP",
    SAT: "SAT",
    toefl: "TOEFL",
    esat: "ESAT",
    sessionDate: "Session date",
    lastLoginDate: "Last login date",
    loginHistory: "Login history",
    fixReservation: "Fix Reservation",
    fixReservations: "Fix Reservations",
    fixReservedSessions: "Fix reserved sessions",
    add_homework: "Add homework",
    restore: "Restore",
    Archive: "Archive",
    Archived: "Archived",
    filter_by_consultants: "Filter by consultants",
    parentReportLink: "Parent report link",
    generateReportLink: "Generate report link",
    entTime: "End time",
    select: "Select",
    doneReviews: "Change reviews state to done",
    doneTeaching: "Change teaching state to done",
  },
  placeholder: {},
};
