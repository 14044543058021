// initial state

const state = () => ({
  applications: [],
  application: null,
  appReadOnly: false,
});

// getters
const getters = {
  applications: (state) => state.applications,
  application: (state) => state.application,
  appReadOnly: (state) => state.appReadOnly,
};

// mutations
const mutations = {
  setApplications(state, payload) {
    state.applications = payload;
  },
  setApplication(state, payload) {
    state.application = payload;
  },
  setAppReadOnly(state, payload) {
    state.appReadOnly = payload;
  },
};

// actions
const actions = {
  setApplications({ commit }, payload) {
    commit("setApplications", payload);
  },
};

export default {
  namespaced: true,
  name: "application",
  state,
  getters,
  actions,
  mutations,
};
