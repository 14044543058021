import api from "@/tools/Api";
import urls from "@/tools/Urls";
import message from "@/tools/Message";
import { _t } from "@/tools/Utils";
import store from "@/store";

export default {
  async getAllTransfers({
    page = 1,
    limit = 10,
    search = "",
    dispatch = null,
    isConfirmed = null,
  }) {
    try {
      const { data } = await api.get(
        urls.qs("getTransfers", {
          index: page,
          size: limit,
          isConfirmed,
          studentName: search,
          classroomName: search,
          shouldOrderByDescending: true,
        })
      );
      if (dispatch) {
        await store.dispatch(`${dispatch}`, data.data);
      }
      return {
        transfers: data.data,
        pageCount: Math.ceil(data.totalCount / limit),
      };
    } catch (e) {
      console.log(e);
    }
  },
  async crateTransfer(payload) {
    try {
      const { isSuccess } = await api.post(
        urls.api("create_transfer"),
        payload
      );
      if (isSuccess) {
        message.success(_t("CREATE_SUCCESS"));
        return isSuccess;
      }
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async deleteTransfer({ transferId }) {
    try {
      const { isSuccess } = await api.delete(urls.api("deleteTransfer"), {
        data: { transferId },
      });
      if (isSuccess) message.success(_t("DELETE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async editTransfer(payload) {
    try {
      const { isSuccess } = await api.put(urls.api("editTransfer"), payload);
      if (isSuccess) {
        message.success(_t("UPDATE_SUCCESS"));
      }
      return isSuccess;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async confirmTransfer(payload) {
    try {
      const { isSuccess } = await api.put(urls.api("confirmTransfer"), payload);
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async getBalanceOfClassroomOfStudent({ publicKey }) {
    try {
      const { data } = await api.get(
        urls.qs("getBalanceOfClassroomOfStudent", { publicKey })
      );
      console.log(data, "getBalanceOfClassroomOfStudent");
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async transferConfirmedByConsultant(payload) {
    try {
      const { isSuccess } = await api.put(
        urls.qs("transferConfirmedByConsultant"),
        payload
      );
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async transferConfirmedByFinancialAdmin(payload) {
    try {
      const { isSuccess } = await api.put(
        urls.qs("transferConfirmedByFinancialAdmin"),
        payload
      );
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
};
