import storage from "./Storage";

// Application Class
// this class handle app control and settings
const app = {
  app_name: "AI.Sino-UK",
  supper_key: "@app_system:",
  fonts: {
    en: "Poppins, sans-serif !important;",
  },
  default_lang: "en",
  test_data: false,
  languages: ["en", "zh"],
  limit: 12,
  rtl_lang: ["fa"],
  lang_items: [
    {
      key: "en",
      title: "English",
      flag: "https://flagcdn.com/56x42/us.png",
    },
    {
      key: "zh",
      title: "Chinese",
      flag: "https://flagcdn.com/56x42/cn.png",
    },
  ],

  // load data with key
  load(key) {
    return storage.get(this.supper_key + key);
  },

  // Save data with key
  save(key, value) {
    storage.set(this.supper_key + key, value);
  },
};

export default app;
