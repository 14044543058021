import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import theme from "@/plugins/colors/theme";
import dashboard from "@/assets/icons/dashboard";
// import "@/styles/variables.scss";

Vue.use(Vuetify);

const options = {
  theme: theme,
  icons: {
    iconfont: "mdi",
    values: {
      dashboard: {
        component: dashboard,
      },
    },
  },
};
export default new Vuetify(options);
