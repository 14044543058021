import api from "@/tools/Api";
import urls from "@/tools/Urls";
import message from "@/tools/Message";
import store from "@/store";
import { event_colors } from "@/packages/admin/schema/calender/COLORS";

export default {
  async getTeacherTimetable({
    page = 1,
    limit = 10,
    all = false,
    lesson = "all",
    role = "Teacher",
    dispatch = null,
    publicKey = null,
    lessonName = null,
    lessonNames = [],
    start = null,
    end = null,
  }) {
    try {
      let schema = {
        page: {
          index: page,
          size: limit,
        },
        where: [],
        order: [
          {
            column: "createMoment",
            descending: true,
          },
        ],
      };
      if (lesson !== "all") {
        schema.where.push([
          {
            column: "lessonName",
            comparison: "Equal",
            operand: lesson,
          },
        ]);
      }
      if (lessonName) {
        schema.where.push([
          {
            column: "lessonName",
            comparison: "Equal",
            operand: lessonName,
          },
        ]);
      }
      if (all) {
        schema.page.index = 1;
        schema.page.size = -1;
      }
      if (publicKey) {
        let array = [];
        for (const key of publicKey) {
          array.push({
            column: "teacherPublicKey",
            comparison: "Equal",
            operand: key,
          });
        }
        if (array.length) schema.where.push(array);
      }
      const { data } = await api.post(
        urls.qs(
          role === "Admin" ||
            role === "TeamLeader" ||
            role === "SchedulingAdmin"
            ? `get_admin_timetable`
            : "get_teacher_timetable",
          { start, end, lessonNames }
        ),
        schema
      );

      console.log(data, `${role} time table`);
      const events = data.data
        .map((table) => {
          return table.availableTimes.map((time) => {
            return {
              ...time,
              teacherTimeTableId: table.teacherTimeTableId,
              teacherPublicKey: table.teacherPublicKey,
              teacherName: table.teacherName,
              lesson: table.lessonName,
              end: time.end * 1000,
              start: time.start * 1000,
              color:
                // time.hasClassroom && time.state !== "Full"
                time.state === "Cancel"
                  ? event_colors.cancel
                  : time.hasClassroom
                  ? event_colors.have_class
                  : time.state === "Active"
                  ? event_colors.waiting_for_class
                  : time.state === "Full"
                  ? event_colors.full
                  : event_colors.canceled,
              timed: true,
            };
          });
        })
        .flat();
      if (dispatch) {
        await store.dispatch(`${dispatch}`, events);
      }
      console.log(events, "events");
      return {
        data,
        events,
      };
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getTeacherTimetableFiltered({
    start = null,
    end = null,
    teacherPublicKey = [],
    lessonNames = [],
    dispatch = null,
  }) {
    try {
      const { data } = await api.post(urls.qs("getTeacherTimetableFiltered"), {
        start,
        end,
        lessonNames,
        teachersPublicKey: teacherPublicKey,
      });

      const events = data
        .map((table) => {
          return table.availableTimes.map((time) => {
            return {
              ...time,
              teacherTimeTableId: table.teacherTimeTableId,
              teacherPublicKey: table.teacherPublicKey,
              teacherName: table.teacherName,
              lesson: table.lessonName,
              end: time.end * 1000,
              start: time.start * 1000,
              color:
                // time.hasClassroom && time.state !== "Full"
                time.state === "Cancel"
                  ? event_colors.cancel
                  : time.hasClassroom
                  ? event_colors.have_class
                  : time.state === "Active"
                  ? event_colors.waiting_for_class
                  : time.state === "Full"
                  ? event_colors.full
                  : event_colors.canceled,
              timed: true,
            };
          });
        })
        .flat();
      if (dispatch) {
        await store.dispatch(`${dispatch}`, events);
      }
      return { events };
    } catch (e) {
      console.log(e);
    }
  },
  async cancelTime(payload) {
    try {
      const { data } = await api.put(urls.api("cancel_time"), payload);
      if (data) message.success("canceled successfully");
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async removeTime(payload) {
    try {
      const { data } = await api.delete(urls.api("remove_time"), {
        data: payload,
      });
      if (data) message.success("Removed successfully");
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async editTimetable(payload) {
    try {
      const { IsSuccess } = await api.put(urls.api("edit_timetable"), payload);
      if (IsSuccess) message.success("Edited successfully");
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async editStartAndEdnTime(payload) {
    try {
      const { data } = await api.put(urls.api("edit_start_end_time"), payload);
      if (data) message.success("Edited successfully");
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
};
