// initial state

const state = () => ({
  transfers: [],
});

// getters
const getters = {
  transfers: (state) => state.transfers,
};

// mutations
const mutations = {
  setTransfers(state, payload) {
    state.transfers = payload;
  },
};
// actions
const actions = {
  setTransfers({ commit }, payload) {
    commit("setTransfers", payload);
  },
};

export default {
  namespaced: true,
  name: "transfer",
  state,
  getters,
  actions,
  mutations,
};
