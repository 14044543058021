import api from "@/tools/Api";
import urls from "@/tools/Urls";
import store from "@/store";
import message from "@/tools/Message";
import { _t } from "@/tools/Utils";

export default {
  async getContracts({
    page = 1,
    limit = 10,
    search = "",
    all = false,
    publicKey = null,
  }) {
    try {
      let schema = {
        page: {
          index: page,
          size: limit,
        },
        where: [],
        order: [
          {
            column: "createMoment",
            descending: true,
          },
        ],
      };
      if (search) {
        schema.where.push([
          {
            column: "studentName",
            comparison: "Contains",
            operand: search,
          },
        ]);
      }
      if (publicKey) {
        schema.where.push([
          {
            column: "studentPublicKey",
            comparison: "Equal",
            operand: publicKey,
          },
        ]);
      }
      if (all) {
        schema.page.index = 1;
        schema.page.size = -1;
      }

      const { data } = await api.post(urls.api("getContracts"), schema);
      await store.dispatch("contract/setContracts", data.data);
      return {
        pageCount: Math.ceil(data.totalCount / limit),
      };
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async addContract(payload) {
    try {
      const { isSuccess } = await api.post(urls.api("addContract"), payload);
      if (isSuccess) message.success(_t("CREATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async editContract(payload) {
    try {
      const { isSuccess } = await api.put(urls.api("editContract"), payload);
      if (isSuccess) message.success(_t("UPDATE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async deleteContract({ contractId }) {
    try {
      const { isSuccess } = await api.delete(urls.api("deleteContract"), {
        data: { contractId },
      });
      if (isSuccess) message.success(_t("DELETE_SUCCESS"));
      return isSuccess;
    } catch (e) {
      console.log(e);
      message.error(e.response.data.Message);
    }
  },
  async getStudentsContractBalance({ studentPublicKey }) {
    try {
      const { data } = await api(
        urls.qs("guideStudentContractBalance", { studentPublicKey })
      );
      return data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
  async uploadContractFile(file, name) {
    try {
      const { data } = await api.post(
        urls.qs("uploadContractFile", { name }),
        file
      );
      console.log(data, "uploadContractFile");
      return data;
    } catch (e) {
      message.error(e.response.data.Message);
      console.log(e);
    }
  },
};
