export default {
  add_student_academic_cost: "classroom/EditStudentsCostForAcademicAdviser",
  add_student_side_cost: "classroom/UpsertStudentSideCosts",
  add_teacher_salary: "classroom/EditTeacherSalaryForFinancialManager",
  finance_get_deposits: "deposit/GetAllDeposits",
  finance_get_deposits_student: "deposit/GetDeposits",
  create_deposit: "deposit/createDeposit",
  edit_deposit: "deposit/editDeposit",
  sum_of_deposits: "deposit/GetSumOfAllDeposits",
  sum_of_student_deposits: "deposit/GetSumOfStudentDeposits",
  student_charge_by_classroom: "deposit/GetStudentChargesByClassroomId",
  student_charges: "deposit/GetStudentCharges",
  all_student_balance: "deposit/GetStudentBalance",
  guideForCreateDeposit: "deposit/GetGuideForCreatingDeposit",
  fixConfirmation: "deposit/fixConfirmationOfReservedSessionsOfClassroom",
  fixConfirmationOfClassrooms:
    "deposit/fixConfirmationOfReservedSessionsOfClassrooms",
  debtorStudents: "deposit/GetTheDeptorStudents",
  get_settlements_admin: "Settlement/GetSettlementsForAdmin",
  get_settlements_all: "Settlement/GetAllSettlement",
  get_settlements: "Settlement/GetSettlements",
  getGuideForCreateSettlement: "Settlement/GetGuideForCreatingSettlement",
  create_settlement: "Settlement/CreateSettlement",
  edit_settlement: "Settlement/EditSettlement",
  get_sum_teacher_settlement: "Settlement/GetSumOfTeacherSettlements",
  get_sum_all_teacher_settlement: "Settlement/GetSumOfAllTeachersSettlements",
  calcClassroomBalance: "Settlement/CalculateClassroomBalance",
  getTeacherSettlementsReport: "Settlement/GetTeacherSettlementsReport",
  getSettlementHistory: "settlement/getSettlementsHistory",
  deleteSettlement: "settlement/deleteSettlement",
  getDepositsHistory: "deposit/GetDepositHistory",
  deleteDeposit: "deposit/deleteDeposit",
  confirmDeposit: "deposit/confirmDeposit",
  exportDeposits: "report/ExportDeposits",
  exportContracts: "report/ExportContractDepositReport",
  exportSideCosts: "report/ExportClassroomSideCostsDepositReport",
  exportSettlements: "report/ExportSettlements",
  exportCoursePlan: "report/ExportCoursePlanOfStudent",
  exportStudentsDeposit: "report/ExportSudentsBalanceReport",
  exportTeachersDeposit: "report/ExportTeachersBalanceReport",
  exportTransfer: "report/ExportTransfersReport",
  exportFinancialGeneralReport: "report/ExportFinancialGeneralReport",
  getACProfit: "deposit/GetAcademicConsultantProfit",
  uploadContractFile: "file/uploadContractFile",
  //transfer
  getTransfers: "transfer/GetAllTransfersWithAdditionalInfos",
  create_transfer: "transfer/createTransfer",
  deleteTransfer: "transfer/DeleteTransfer",
  editTransfer: "transfer/EditTransfer",
  confirmTransfer: "transfer/ConfirmTransfer",
  getBalanceOfClassroomOfStudent: "transfer/getBalanceOfClassroomsOfStudent",

  //
  confirmedByConsultant: "deposit/ConfirmDepositByAcademicConsultant",
  confirmedByFinancialAdmin: "deposit/ConfirmDepositByFinancialAdmin",
  //
  transferConfirmedByConsultant: "transfer/ConfirmTransferByAcademicConsultant",
  transferConfirmedByFinancialAdmin: "transfer/ConfirmTransferByFinancialAdmin",
  //
  getGuideForGroupCreateSettlement:
    "settlement/GetManyGuidesForCreatingSettlements",
  createGroupSettlement: "settlement/CreateManySettlements",
  getTeacherMonthlyHours: "settlement/GetTeacherMonthlyClassroomsHours",
  //fee
  getAllTeachersTeachingFee: "TeacherFee/GetAllTeachersTeachingFee",
  getATeacherTeachingFee: "TeacherFee/GetAllTeachersTeachingFee",
  upsertTeachingFee: "TeacherFee/UpsertTeachingFee",
  deleteTeacherFee: "TeacherFee/DeleteTeacherTeachingFees",
};
